.react-dropdown-select-input{
  width: 100% !important;
}

.dob-border{
  border: 1px solid #E8E8E8;
}

.date-picker-padding{
  padding: 6px 14px;
}

.form-group div.is-invalid,
.form-group input.is-invalid,
.form-group select.is-invalid {
  border: 1px solid red !important;
  box-shadow: none !important;
}