.custom-modal-content {
  border-radius: 30px !important;
}

.staff-modal-title {
  font-weight: bold !important;
}

.staff-schedule-save-btn {
  border: 1px solid black !important;
  width: 200px !important;
  height: 35px !important;
  color: white !important;
  background-color: #3b4eff !important;
  border-radius: 15px !important;
  margin-top: 25px !important;
  font-size: 18px !important;
  font-weight: bold !important;
}
