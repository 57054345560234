.team-calendar {
  background-color: #fff;
  border-bottom: 2px solid #ebeff4;
  table-layout: fixed;
  width: 100% !important;
}

.add-schedule-btn {
  width: 185px !important;
  margin-left: -20px !important;
}

@media (max-width: 767px) {
  .team-calendar {
    width: 800px !important;
  }

  .add-schedule-btn {
    margin-left: -5px !important;
    width: 160px !important;
  }

  .team-cal {
    width: 800px;
  }
}

.team-calendar-header {
  text-align: center;
}

.team-calendar-header-cell {
  background-color: #ebeff4;
  border-left: 2px solid #ebeff4;
  border-left-color: #fff;
  padding: 10px;
}

.team-calendar-header-cell-day-header {
  background-color: #ebeff4;
  border-left: 2px solid #ebeff4;
  border-left-color: #fff;
  padding: 10px;
  width: 200px;
}

.team-calendar-header-cell-day {
  background-color: #ebeff4;
  border-left: 2px solid #ebeff4;
  border-left-color: #fff;
  padding: 10px;
  width: 100px;
}

.team-calendar-header-day {
  font-size: 14px;
  font-weight: 400;
}

.team-calendar-header-date {
  font-size: 18px;
}

.team-calendar-header-day {
  font-size: 14px;
}

.team-calendar-header-section {
  background-color: #ebeff4;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
  padding: 14px;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  color: #656565;
}

.team-calendar-cell,
.team-calendar-staff-cell {
  border: 1px solid #d5d9df;
  text-align: center; /* Center aligns the content horizontally within the cell */
  vertical-align: middle; /* Middle aligns the content vertically within the cell */
}

.centered-content {
  display: inline-block; /* Allows horizontal centering */
  line-height: normal; /* Resets the line height */
}

.team-calendar-cell:hover {
  cursor: pointer;
  background-color: whitesmoke;
}

.team-calendar-staff-col {
  padding: 14px;
  display: flex;
}

.team-calendar-staff-col-info {
  padding-left: 5px;
}

.team-calendar-staff-col-info-name {
  color: #656565;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

.team-calendar-staff-col-info-role {
  color: #898d96;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}

.team-calendar-staff-hours {
  margin-left: auto;
  font-size: 14px;
  font-weight: bold;
  color: darkgrey;
}

.team-calendar-date-selector {
  display: flex;
  align-items: center;
  justify-content: center;
}
