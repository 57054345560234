.invoice-receipt-container {
  width: 80%;
  display: block;
  margin: auto;
  animation: fadein 0.25s;
  border-radius: 26px;
  border: 1px solid #e5e2e2;
  margin-bottom: 30px;
}

.invoice-recipt-header-container {
  position: relative;
  min-height: 240;
  font-family: "Open Sans";
  border-top-left-radius: 26px;
  border-top-right-radius: 26px;
}

.date-section {
  position: absolute;
  top: 10%;
  right: 66px;
  text-align: right;
  width: 32%;
}

.invoice-date {
  font-size: 14px;
  opacity: 1;
  font-weight: 500;
  color: #495b8c;
  /* margin-bottom: 10px; */
  width: 80%;
  text-transform: uppercase;
}

.invoice-date-range {
  font-size: 14px;
  opacity: 1;
  font-weight: 500;
  color: #495b8c;
  /* margin-bottom: 10px; */
  width: 80%;
  text-transform: uppercase;
}

.downloadButton {
  margin-top : 15px;
  margin-left: 15px;
  height: 20px;
  width: 20px;
}

.invoice-due-date {
  font-size: 14px;
  opacity: 1;
  font-weight: 500;
  color: #495b8c;
  /* margin-bottom: 10px; */
  width: 80%;
  text-transform: uppercase;
}

.date-item-list {
  display: flex;
  align-items: center;
  text-align: justify;
  margin-bottom: 3px;
}

.invoice-control {
  display: block;
  width: 127%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #8c8ea0;
  background-color: #fff;
  background-clip: padding-box;
  border: 1.5px solid #edf4ff;
  border-radius: 0.2rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.invoice-status-container {
  width: 80%;
  margin: auto;
  border: 1px solid #e5e2e2;
  padding: 15px 35px;
  margin-bottom: 20px;
  background: white;
  font-family: "Open Sans";
}

.invoice-status-inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.invoice-status-label {
  margin-right: 20px;
  margin-right: 20px;
  font-size: 14px;
  opacity: 1;
  font-weight: 500;
  color: #495b8c;
  width: 80%;
  text-transform: uppercase;
}

.invoice-status-content {
}

.status-select-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8c8ea0;
  background-color: #fff;
  background-clip: padding-box;
  border: 1.5px solid #bdcbe0;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.add-item-btn-section {
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 20px;
  align-items: center;
  justify-content: space-between;
}

.add-item-btn {
  /* background-color: white;
  border: 1px solid #3b4eff;
  width: 200px;
  border-radius: 7px !important;
  color: #3b4eff;
  padding: 8px !important;
  font-weight: 600;
  box-shadow: 4px 4px 6px 0px rgb(191 190 190 / 75%);
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px; */
  padding: 6px 20px;
  border-radius: 2px;
  margin-right: 20px;
  color: white;
  font-weight: 700;
  background: rgb(92, 200, 220);
  border: 1px solid rgb(92, 200, 220);
  box-shadow: rgb(205 205 205) 2px 2px 5px 2px;
  font-size: 13px;
  cursor: pointer;
}

.invoice-receipt-item-menu {
  font-size: 14px;
  padding: 10px;
  min-width: 45px;
  color: dimgrey;
}

.invoice-receipt-footer-section {
  border-top: 1.5px solid #dbdde3;
}

.total-discount-container {
  display: flex;
  justify-content: space-between;
}

.discount-total {
  padding: 10px;
  margin-right: 10px;
  color: #2639d2;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
}

.invoice-name {
  font-size: 18px;
  opacity: 1;
  font-weight: 700;
  color: #000000;
  text-transform: uppercase;
}

.invoice-email {
  font-size: 16px;
  opacity: 1;
  font-weight: 600;
  color: #000000;
}

.invoice-billing-info-section {
  position: absolute;
  top: 55%;
  right: 15px;
  text-align: right;
}

.invoice-bill-title {
  font-size: 16px;
  opacity: 1;
  font-weight: 700;
  color: #2333ba;
  /* margin-bottom: 10px; */
  text-transform: uppercase;
}

.invoice-bill-name {
  font-size: 13px;
  opacity: 1;
  font-weight: 600;
  /* margin-bottom: 10px; */
  color: #2333ba;
}

.invoice-bill-address1 {
  font-size: 13px;
  opacity: 1;
  font-weight: 600;
  /* margin-bottom: 10px; */
  color: #2333ba;
}

.invoice-bill-address2 {
  font-size: 13px;
  opacity: 1;
  font-weight: 600;
  /* margin-bottom: 10px; */
  color: #2333ba;
}

.invoice-receipt-table-container {
  background: white;
  border-bottom-left-radius: 26px;
  border-bottom-right-radius: 26px;
}

.invoice-receipt-item-content {
  font-size: 14px;
  padding: 10px;
  min-width: 100px;
  color: dimgrey;
  padding-left: 22px;
}

.order-receipt-item-section .align-right {
  text-align: right;
}

.invoice-receipt-table-header {
  font-size: 16px;
  font-weight: normal;
  padding: 15px 10px;
  font-weight: 600;
  padding-left: 23px;
}

.invoice-message-container {
  width: 60%;
  margin: auto;
  display: block;
  margin-bottom: 40px;
  font-family: "Open Sans";
  font-weight: 500;
  color: dimgrey;
}

.invoice-scheduled-date-section {
  background: #ebebeb;
  padding: 10px 10px;
  font-size: 14px;
  color: #272727;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  font-weight: 700;
}

.invoice-scheduled-message-section {
  background: white;
  padding: 20px 25px;
  height: 250px;
}

.next-date-container {
  width: 60%;
  margin: auto;
  display: block;
  margin-bottom: 40px;
  font-family: "Open Sans";
  border-radius: 0px;
  background-color: #a7a7a7;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.next-control {
  width: 125px;
  display: inline-block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #8c8ea0;
  background-color: #fff;
  background-clip: padding-box;
  border: 1.5px solid #edf4ff;
  border-radius: 0.2rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media only screen and (max-width: 769px) {
  .downloadButton {
    height: 10px;
    width: 10px;
  }
  .order-header-background{
    min-height: 25vh !important;
  }
  .order-name-section {
    max-width: 25vw !important;
  }

  .invoice-billing-info-section {
    top: 60% !important;
  }
  .order-name-section > div > strong{
    font-size: 7px;
  }

  .date-section > .date-item-list {
    text-align: unset !important;
  }

  .date-section{
    width: max-content !important;
    right: 15px !important;
    text-align: unset !important;
  }

  .react-datepicker-wrapper {
    margin-left: 5px;
  }

  .invoice-control {
    max-width: 15vw !important;
    font-size: calc(0.8rem - 7px) !important;
    padding: 0px 5px;
  }

  .invoice-name {
    font-size: 10px;
  }

  .invoice-date, .invoice-date-range, .invoice-due-date {
    font-size: 7px;
    padding-right: 5px;
    margin-right: -10px;
  }

  .invoice-email {
    font-size: 8px;
  }

  .invoice-bill-title {
    font-size: 8px;
  }

  .invoice-bill-name, .invoice-bill-address1, .invoice-bill-address2 {
    font-size: 7px;
  }

  .add-item-btn-section {
    margin: 5px;
    width: fit-content;
    padding-left: 5px;
    padding-bottom: 5px;
  }

  .add-item-btn {
    font-size: 7px;
    box-shadow: rgb(205 205 205) 1px 1px 2px 1px;
    padding: 3px;
  }

  .discount-total {
    font-size: calc(1rem - 7px);
    padding: 5px 10px;
    margin: 0px;
  }
  .invoice-receipt-table-container {
    padding-bottom: 8px;
    overflow-x: scroll;
  }
  .invoice-receipt-table-header {
    font-size: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 10px;
  }

  .invoice-receipt-item-menu {
    font-size: 10px;
  }

  .invoice-receipt-item-content {
    font-size: 8px;
    padding-left: 5px;
    min-width: unset;
    max-width: 15vw;
  }

  .invoice-receipt-footer-section > .invoice-receipt-item-content {
    text-wrap: nowrap;
  } 
}