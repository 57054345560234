.notes-custom-modal-content {
  border-radius: 30px !important;
  margin-top: 100px !important;
}

.restore-enrollment-tooltip{
  width: 90px !important;
  text-align: center;
  display: flex !important;
  justify-content: center !important;
}