/* Modal specific CSS */

.request-modal-cont .request-modal {
  height: min-content;
  display: flex;
  flex-direction: column;
  padding: 35px 30px 25px !important;
  max-height: 70vh;
}

.request-modal .modal-head button.MuiButtonBase-root {
  margin-right: 0 !important;
  padding: 12px !important;
  border-radius: 50%;
  color: #2880ff;
  margin-bottom: 0.5rem;
}

.request-modal .modal-head button.MuiIconButton-root:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.request-modal .modal-banner {
  height: 30px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.request-modal .modal-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.request-modal .modal-footer {
  width: 100%;
}

.request-modal .modal-main {
  overflow: auto;
  overflow-y: inherit;
  height: min-content;
  max-height: 30rem;
  padding-right: 10px;
  padding-bottom: 30px;
  margin: 1rem 0 0.25rem;
  scrollbar-width: thin;
}

.request-modal ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.request-modal ::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
}

.request-modal ::-webkit-scrollbar-thumb {
  background-color: #ddd;
  width: 6px;
  border-radius: 8px;
}

.request-modal > form {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.request-modal p.confirmation,
.request-modal p.warning {
  font-size: 16px;
  text-align: center;
  margin-bottom: 0;
}

.request-modal .policy-container p.policy {
  font-size: 14px;
  margin-bottom: 4px;
}

.request-modal .policy-container div {
  display: flex;
  align-items: center;
  margin-top: 4px;
}
