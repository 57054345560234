/* form {
    margin: 20px auto;
    width: 420px;
}
.form-row {
    padding: 5px 10px;
}
label {
    display: block;
    margin: 3px 0;
}
.form-row input {
    padding: 3px 1px;
    width: 100%;
} */
input.currency {
    text-align: right;
    padding-right: 15px;
}
.input-group {
    float: none;
}
.input-group .input-buttons {
    position: relative;
    z-index: 3;
}

.input-group-addon{
    border: 1px solid lightgrey;
    background: lightgrey;
    color: dimgrey;
    font-size: 15px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}