#milestone-title {
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: "Open sans", sans-serif;
  width: 250px !important;
}

.parent-milestone-details-btn {
  width: 90px;
}

.parent-milestone-details-title {
  padding-left: 20px;
  font-family: "Open sans", sans-serif;
}

.parent-milestone-details-modal {
  font-size: 1.3rem !important;
}

.parent-milestone-details-modal-header {
  position: relative;
}

.parent-milestone-details-modal-header::before {
  content: "";
  position: absolute;
  left: -100px; /* Adjust the value to extend the border line to the left */
  right: -100px; /* Adjust the value to extend the border line to the right */
  top: 47px; /* Adjust the value to make the line longer below the border */
  border-bottom: 1px solid lightgrey; /* Set the style, thickness, and color of the border line */
}

.order-width,
.order-description {
  width: 250px !important;
}
