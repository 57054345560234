.user-img-top-bar {
  margin-right: 12px;
}

.franchise-popup-container {
  position: absolute;
  top: 56px;
  left: 23px;
  background-color: white;
  width: 200px;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 0.9rem;
  color: #050f3f;
  box-shadow: 0 2px 15px 0 rgba(204, 204, 204, 0.5);
}

.popup-container {
  position: absolute;
  top: 56px;
  left: -50px;
  background-color: white;
  width: 200px;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 0.9rem;
  color: #050f3f;
  box-shadow: 0 2px 15px 0 rgba(204, 204, 204, 0.5);
}

.popup-container:hover {
  color: #73d7d5 !important;
}

.inner-popup-container {
  list-style-type: none;
  padding-left: 0;
}

.popup-item {
  padding: 10px !important;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
}

.popup-item:first-child {
  border-top: none;
}

.popup-item:last-child {
  border-bottom: none;
}

.popup-link {
  text-decoration: none !important;
  color: #050f3f !important;
}

.popup-link:hover {
  text-decoration: none !important;
  color: #73d7d5 !important;
  font-weight: 500 !important;
}

.search-bar-container {
  /* padding-top: 20vh; */
  width: 40%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
}

/* Tablets */
@media only screen and (max-width: 960px) {
  .user-img-top-bar {
    margin-right: 12px;
  }

  .franchise-popup-container {
    position: absolute;
    top: 56px;
    left: -118px;
    background-color: white;
    width: 200px;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 0.9rem;
    color: #050f3f;
    box-shadow: 0 2px 15px 0 rgba(204, 204, 204, 0.5);
  }

  .popup-container {
    position: absolute;
    top: 56px;
    left: -118px;
    background-color: white;
    width: 200px;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 0.9rem;
    color: #050f3f;
  }

  .popup-container:hover {
    color: #73d7d5 !important;
  }

  .inner-popup-container {
    list-style-type: none;
    padding-left: 0;
  }

  .popup-item {
    padding: 10px !important;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
  }

  .popup-item:first-child {
    border-top: none;
  }

  .popup-item:last-child {
    border-bottom: none;
  }

  .popup-link {
    text-decoration: none !important;
    color: #050f3f !important;
  }

  .popup-link:hover {
    text-decoration: none !important;
    color: #73d7d5 !important;
    font-weight: 500 !important;
  }
}

/* Phones */
@media only screen and (max-width: 767px) {
  .user-img-top-bar {
    margin-right: 0px;
  }

  .popup-container {
    position: absolute;
    top: 56px;
    left: -143px;
    background-color: white;
    width: 200px;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 0.9rem;
    color: #050f3f;
  }

  .popup-container:hover {
    color: #73d7d5 !important;
  }

  .inner-popup-container {
    list-style-type: none;
    padding-left: 0;
  }

  .popup-item {
    padding: 10px !important;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
  }

  .popup-item:first-child {
    border-top: none;
  }

  .popup-item:last-child {
    border-bottom: none;
  }

  .popup-link {
    text-decoration: none !important;
    color: #050f3f !important;
  }

  .popup-link:hover {
    text-decoration: none !important;
    color: #73d7d5 !important;
    font-weight: 500 !important;
  }
}
