/* Removing the additional input field from the dropdpwn field */

.timezonepicker-row{
    background: white;
    width: 700px;
    margin: auto;
    display: block;
    /* justify-content: flex-start; */
    align-items: center;
    margin-top: 20px;
    /* height: 470px; */
    border-radius: 10px;
    box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
    margin-top: 30px;
}

.timezone-section-header{
    padding: 13px 20px 4px;
    border-bottom: 1px solid gainsboro;
}

.timezone-content{
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-bottom: 10px;
}

.timezonepicker-label{
    padding: 12px 20px 5px;
}

.timezonepicker-title{
    font-size: 17px !important;
    font-weight: 600 !important;
    color: #696969;
}

.timezonepicker-container{
    width: 75% !important;
}









/* ============================================================================= */
/* The following styles the react-dropdown-select box and its internal elements! */
/* ============================================================================= */

.timezonepicker-container .react-dropdown-select {
    height: 40px;
    width: 100%;
    font-size: 0.93rem;
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    color: #797979;
    border-radius: 4px;
}

.timezonepicker-container .react-dropdown-select-content input{
    width: 5px !important;
    /* border: 1px dotted green !important; */
    flex-grow: 1;
}

.timezonepicker-container .react-dropdown-select-content input:focus {
    width: 100% !important;
    /*position: absolute;*/
    /*top: 0px;*/
    /*left: 0px;*/
    /*height: 100%;*/
    /*background: white !important;*/
    /*z-index: 1;*/
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    color: inherit !important;
}

.timezonepicker-container .react-dropdown-select-content:focus-within span {
    display: none;
    /* border: 1px solid red !important; */
}


/* This is for placeholder when no other value is selected */
.timezonepicker-container .react-dropdown-select-content input::placeholder {
    font-size: 0.93rem;
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    color: #797979;
    /*color: red;   <-- used for testing!*/
}
/* This is for placeholder when a value is selected and dropdown is NOT active */
.timezonepicker-container .react-dropdown-select-content span + input::placeholder {
    color: #00000000;  /*invisible*/
    /*color: lightgreen;   <-- used for testing!*/
}
/* This is for placeholder when a value is selected and dropdown is active */
.timezonepicker-container .react-dropdown-select-content:focus-within span + input::placeholder {
    color: #797979;
    /*color: royalblue;   <-- used for testing!*/
}
