.conn-upper-tabs {
  width: 85%;
  margin: 16px auto;
}

.fam-parent-item-row {
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 0.5rem 0;
  min-height: 60px;
  border-bottom: 1px dashed lightgrey;
}

.fam-parent-avatar {
  margin-left: 25px;
}

.fam-parent-name {
  margin-left: 25px;
  color: #61616f;
  font-weight: 600;
  width: 25%;
  font-family: "Open Sans", sans-serif;
}

.fam-parent-type {
  color: #61616f;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
}

.fam-child-item-row {
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 0.5rem 0;
  min-height: 60px;
  border-bottom: 1px dashed lightgrey;
}

.fam-child-avatar {
  margin-left: 25px;
}

.fam-child-name {
  margin-left: 25px;
  color: #61616f;
  font-weight: 600;
  width: 25%;
  font-family: "Open Sans", sans-serif;
}

.fam-child-type {
  color: #61616f;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
}

.conn-profile-item-row {
  display: flex;
  align-items: center;
}

.conn-profile-label {
  width: 20%;
  font-family: "Open Sans", sans-serif;
  color: grey;
  font-weight: 600;
  font-size: 14px;
  border-right: 1px solid #d4d4d5;
  height: 100%;
  padding: 14px;
  text-transform: uppercase;
}

.conn-input-field {
  width: 100%;
  border: none;
  border-bottom: 1px solid lightgrey;
}

.conn-input-field:focus {
  outline: none;
  border-bottom: 1px solid lightgrey;
}

.conn-input-field:disabled {
  background: #fbfbfb;
}

.conn-btn-section {
  display: flex;
  width: 15%;
  justify-content: center;
  align-items: center;
}

.conn-btn {
  padding: 6px 13px;
  border-radius: 10px !important;
  margin-right: 20px;
  color: white;
  font-weight: 700;
  background: rgb(92, 200, 220);
  border: 1px solid rgb(92, 200, 220);
  box-shadow: rgb(205 205 205) 2px 2px 5px 2px;
}

#milestone-icon {
  margin-right: 24px !important;
}
