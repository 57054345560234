/* .ui.dropdown .menu > .item > .image, .ui.dropdown .menu > .item > img {
    max-width: 20% !important;
    height: 100% !important;
    border-radius: 35% !important;
} */
/* 
.ui.avatar.image {
    height: 100%;
    border-radius: 35%;
} */

.ui.dropdown .menu>.item {
    height: 50px !important;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
}

.ui.default.dropdown:not(.button)>.text, .ui.dropdown:not(.button)>.default.text {
    color: #6f6f6fde;
}