/* 
Mostly styling for each of the single notifications.
*/

.no-notification-text {
  text-align: center;
  width: 100%;
  display: block;
  margin: 20px 0px;
  font-style: italic;
  color: black;
}

.notification,
.notification:active {
  display: flex;
  border-top: 1px solid #e3e3e3;
  align-items: center;
  padding: 5px;
  font-family: Montserrat;
  background: #f1f1f1;
  width: 100%;
  justify-content: space-between;
  color: #222;
  transition: background 0.15s ease-out;
}

.notification:hover {
  background: #dadada;
}

.notification.seen:hover {
  background: #e3e3e3;
}

.notification.seen,
.notification.seen:active {
  background: #fff;
}

.notification.link {
  cursor: pointer;
}

.notification .img-wrapper {
  display: flex;
  justify-items: middle;
}

.notification .img-wrapper img {
  width: 32px !important;
  height: 32px;
  margin: 10px;
}

.notification .main {
  margin-left: 10px;
  min-width: 0;
  flex-grow: 1;
  justify-self: flex-start;
}

.notification .main .head {
  font-weight: 600;
  font-size: 14px;
}

.notification .main .body {
  font-size: 12px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}

.notification .timestamp {
  width: 100px;
  font-size: 12px;
  height: fit-content;
  text-align: right;
  padding: 0;
  white-space: nowrap;
}

/* .notification-wrapper ::-webkit-scrollbar-thumb {
  width: 6px;
}

.notification-wrapper ::-webkit-scrollbar-track {
  width: 6px;
} */

/* Scrollbar Styling */
.notification-wrapper ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.notification-wrapper ::-webkit-scrollbar-track {
  background-color: transparent;
}

@media only screen and (max-width: 650px) {
  .raf-icon-badge + div {
    position: fixed !important;
    left: 5% !important;
    top: 50px !important;
    width: 90% !important;
  }
  .raf-dropdown-panel::after {
    content: none !important;
  }
}
