.integrations-grid-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
  justify-content: center; /* Center the grid items */
}

.integration-icon img {
  width: 40px; /* Adjust image size */
  height: 40px;
  object-fit: contain;
  margin-bottom: 10px;
}

.integration-card {
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  transition: transform 0.2s ease-in-out;
  width: 300px; /* Fixed width for each card */
}

.integration-card:hover {
  transform: translateY(-5px);
}

.new-feature {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #ffcc00;
  color: black;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px;
}

.integration-icon {
  font-size: 30px;
  margin-bottom: 10px;
}

.connect-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.connect-button:hover {
  background: #0056b3;
}

.connected-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Adjust spacing */
  width: 100%; /* Ensure full width */
}

.view-button {
  background: white;
  color: #007bff;
  border: 1px solid #007bff;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px; /* Decrease margin */
}

.view-button:hover {
  background: #f0f0f0;
}

.connected-status {
  display: flex;
  align-items: center;
  margin-right: 10px; /* Decrease margin */
}

.checkmark {
  color: green;
  font-size: 18px;
  margin-right: 5px;
}

.connected-text {
  color: green;
  font-size: 14px;
}

.disconnect-icon {
  color: red;
  font-size: 18px;
  cursor: pointer;
}

.disconnect-icon:hover::after {
  content: "Disconnect";
  position: absolute;
  background: #333;
  color: white;
  padding: 5px;
  border-radius: 5px;
  top: -30px;
  right: 0;
  white-space: nowrap;
  z-index: 1;
}
