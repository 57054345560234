.console-pages .messagecenter .leftside {
  width: 400px;
  min-height: 95vh;
  background: #f4f6ff;
  display: flex;
  flex-direction: column;
}
.admin .pb-5 {
  padding-bottom: 0px;
}
.console-pages .messagecenter .rightside {
  width: 270px;
  height: calc(100% - 55px);
  background: #fff;
  position: absolute;
  top: 70px;
  right: 0px;
}

.console-pages .messagecenter .centerm {
  background: #eceffa;
  min-height: calc(100vh -55px);
  width: 100%;
  position: relative;
}

.console-pages .messagecenter .centerm .paddingmsg {
  height: calc(100vh - 140px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: 5px;
  padding-right: 5px;
  /* min-height: 600px; */
}
.button-pos-back {
  position: absolute;
  top: 0px;
  left: 0px;
}

.console-pages .messagecenter .centerm .paddingmsg > div:first-child {
  overflow-x: hidden;
}

.console-pages .messagecenter .centerm ::-webkit-scrollbar-track {
  -webkit-box-shadow: 0;
  background-color: transparent;
}

.console-pages .messagecenter .centerm ::-webkit-scrollbar {
  width: 3px;
  background-color: transparent;
}

.console-pages .messagecenter .centerm ::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0.4;
}

.console-pages .messagecenter .centerm .leftsidemsg {
  max-width: 550px;
  display: block;
  width: 100%;
}

.console-pages .messagecenter .centerm .leftsidemsg .divforimg {
  position: absolute;
  display: inline-block;
  bottom: 0;
  right: 10px;
}

.console-pages .messagecenter .centerm .leftsidemsg .divforimg img {
  max-width: 40px;
}

.console-pages .messagecenter .centerm .leftsidemsg .divforimg .mincircle {
  display: block;
  width: 11px;
  height: 11px;
  background: #7643ff;
  border: 1px solid #fff;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.console-pages .messagecenter .centerm .leftsidemsg .activecircle .mincircle {
  display: block;
}

.console-pages .messagecenter .centerm .leftsidemsg .msg p {
  background: #7ea1ff;
  color: #fff;
  max-width: 503px;
  display: inline-block;
  padding: 12px;
  border-radius: 12px;
  font-size: 13px;
  line-height: 18px;
  margin: 0;
  margin-top: 10px;
}

.console-pages .messagecenter .centerm .leftsidemsg .msg p:first-child {
  margin-top: 45px;
}

.console-pages .messagecenter .centerm .leftsidemsg .msg .time {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 9px;
  color: #454545;
  padding: 0;
  background: transparent;
  position: absolute;
  left: 15px;
  bottom: -24px;
}

.console-pages .messagecenter .centerm .leftsidemsg .msg .leftmsgarrow {
  position: absolute;
  bottom: -1px;
  left: 0px;
}

.console-pages .messagecenter .centerm .floatright {
  text-align: right;
}

.console-pages .messagecenter .centerm .rightsidemsg {
  max-width: 550px;
  display: inline-block;
  width: 100%;
}

.console-pages .messagecenter .centerm .rightsidemsg .divforimg {
  position: absolute;
  display: inline-block;
  bottom: 0;
  left: 10px;
}

.console-pages .messagecenter .centerm .rightsidemsg .divforimg img {
  max-width: 40px;
}

.console-pages .messagecenter .centerm .rightsidemsg .divforimg .mincircle {
  display: none;
  width: 11px;
  height: 11px;
  background: #7643ff;
  border: 1px solid #fff;
  border-radius: 50%;
  position: absolute;
  left: 4px;
  bottom: 3px;
}

.console-pages .messagecenter .centerm .rightsidemsg .activecircle .mincircle {
  display: block;
}

.console-pages .messagecenter .centerm .rightsidemsg .msg {
  text-align: right;
}

.console-pages .messagecenter .centerm .rightsidemsg .msg p {
  background: #fff;
  color: #000;
  max-width: 503px;
  display: inline-block;
  padding: 12px;
  border-radius: 12px;
  font-size: 13px;
  line-height: 18px;
  margin: 0;
  text-align: left;
  margin-top: 10px;
}

.console-pages .messagecenter .centerm .rightsidemsg .msg p:first-child {
  margin-top: 45px;
}

.console-pages .messagecenter .centerm .rightsidemsg .msg .time {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 9px;
  color: #454545;
  padding: 0;
  background: transparent;
  position: absolute;
  right: 15px;
  bottom: -24px;
}

.console-pages .messagecenter .centerm .rightsidemsg .msg .rightmsgarrow {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.console-pages .messagecenter .centerm .timedate {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #c6c6c6;
  line-height: 25px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 15px;
}

.console-pages .messagecenter .upermenu {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  border-bottom: 1px solid #d5d5d5;
}

.console-pages .messagecenter .upermenu .leftm {
  text-align: left;
}

.console-pages .messagecenter .upermenu .leftm a {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
  padding-top: 10px;
  padding-bottom: 10px;
  display: inline-block;
}

.console-pages .messagecenter .upermenu .rightm {
  text-align: right;
}

.console-pages .messagecenter .upermenu .rightm a {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
  padding-top: 20px;
  padding-bottom: 20px;
  display: inline-block;
}

.console-pages .messagecenter .upermenu .active {
  border-bottom: 3px solid #7ea1ff;
}

.console-pages .messagecenter .upermenu a {
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.console-pages .messagecenter .upermenu a span {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 11px;
  line-height: 17px;
  color: #fff;
  width: 17px;
  height: 17px;
  background: #7ea1ff;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  text-align: center;
}

.console-pages .messagecenter .upermenu a:hover {
  opacity: 0.7;
  text-decoration: none;
}

.console-pages .messagecenter .msg-search .select {
  text-align: right;
  position: relative;
  margin-top: 11px;
}

.console-pages .messagecenter .msg-search .select select {
  display: inline-block;
  border: 0;
  border-radius: 5px;
  -webkit-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 5px 30px 5px 11px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  color: #7ea1ff;
  margin: 0;
  border: #7ea1ff 1px solid;
  border-radius: 0;
  max-width: 120px;
  background: #fff;
}

.console-pages .messagecenter .msg-search .select .arrowdownselect {
  position: absolute;
  right: 10px;
  top: 12px;
  text-outline: 4px;
}

.console-pages .messagecenter .msg-search .select select:focus {
  outline: none;
}

.console-pages .messagecenter .sendmsg {
  text-align: right;
  display: block;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #919191;
}

.console-pages .messagecenter .sendmsg .input {
  position: relative;
}

.console-pages .messagecenter .sendmsg .input input {
  background: transparent;
  border: 0;
  width: 100%;
  padding-bottom: 10px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #c9c9c9;
  font-size: 15px;
  padding: 10px 25px;
  margin: 0;
}

.console-pages .messagecenter .sendmsg .input input:focus {
  outline: none;
}

.console-pages .messagecenter .sendmsg .input button {
  background: transparent;
  border: 0;
  position: absolute;
  right: 5px;
  top: 8px;
}

.console-pages .messagecenter .sendmsg .input button:focus {
  outline: none;
}

.console-pages .messagecenter .msgs {
  margin-top: 5px;
}

.console-pages .messagecenter .msgs h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin: 0;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
}

.console-pages .messagecenter .msgs p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #000;
  margin: 0;
  margin-top: -3px;
  line-height: 28px;
  opacity: 0.8;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* width: 100%; */
  /* max-width: 100%; */
  /* display: block; */
  overflow: hidden;
}

.console-pages .messagecenter .msgs .bolder {
  font-weight: 700;
  opacity: 1;
}

.console-pages .messagecenter .msgs h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 58px;
  color: #000000;
  margin: 0;
  text-align: right;
}

.console-pages .messagecenter .msgs .contact {
  display: block;
  padding: 15px;
  width: 100%;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
  cursor: pointer;
  border-bottom: 1px solid #c6c6c6;
}
.console-pages .messagecenter .msgs .contact:last-child {
  border-bottom: none;
}

.console-pages .messagecenter .msgs .contact:hover {
  text-decoration: none;
  background: #fff;
}

.console-pages .messagecenter .msgs .active {
  background: #fff;
}

.console-pages .messagecenter .msgs .divforimg {
  position: relative;
}

.console-pages .messagecenter .msgs .divforimg img {
  max-width: 60px;
}

.console-pages .messagecenter .msgs .divforimg .mincircle {
  display: block;
  width: 11px;
  height: 11px;
  border: 1px solid #fff;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.console-pages .messagecenter .msgs .activecircle .mincircle {
  display: block;
}

.console-pages .messagecenter .msgs .opacitytime {
  opacity: 0.4;
}

.console-pages .messagecenter .imgrightside {
  margin-top: 93px;
}

.console-pages .messagecenter .imgrightside img {
  display: block;
  margin: auto;
  max-width: 100%;
}

.console-pages .messagecenter .imgrightside h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 17px;
  color: #000000;
  text-align: center;
  margin: 0;
  margin-top: 20px;
}

.console-pages .messagecenter .imgrightside p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 17px;
  color: #4b4b4b;
  text-align: center;
  margin: 0;
  margin-top: 5px;
}

.console-pages .messagecenter .desc {
  margin-top: 70px;
}

.console-pages .messagecenter .desc p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #000;
  border-bottom: 0.6px solid #d6d6d6;
  padding-left: 29px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 0;
}

.console-pages .messagecenter .desc span {
  color: #777777;
}

.console-pages .messagecenter .desc p:last-child {
  border: 0;
}
@media (min-width: 801px) {
  .console-pages .messagecenter .leftside {
    max-height: 95vh;
    overflow-y: scroll;
  }
}
.leftside::-webkit-scrollbar {
  display: none;
}
/*************************************************
	        @MEDIA(MAX-WIDTH: 767PX)
*************************************************/
@media (max-width: 800px) {
  .console-pages .messagecenter .leftside {
    width: 100%;
    overflow: hidden;
  }
  .d-md-block .d-none {
    display: block;
  }
}
