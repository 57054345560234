.console-pages .ds-button {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  color: #fff;
  padding: 10px 25px;
  background: #9bb0eb;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
  border: 0;
  margin: 0 1px;
}

.console-pages .ds-button:hover {
  background: #915aff;
  text-decoration: none;
}

.console-pages .ds-button:disabled {
  background: #c8d3f3;
  text-decoration: none;
}
