@import url("https://use.typekit.net/tsh3lwo.css");
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */

.base-modal-outer-wrapper {
  backdrop-filter: blur(5px);
  transition: all 300ms ease;
}

.base-modal-wrapper {
  background-color: #fff;
  margin: auto;
  border-radius: 14px;
  box-shadow: 0px 4px 20px rgba(67, 76, 159, 0.25);
  position: relative;
  min-height: 270px !important;
  max-height: 650px !important;
  /* font-family: "Open Sans", sans-serif !important; */
  /* font-family: "Montserrat", sans-serif !important ; */
  /* font-family: "Poppins", sans-serif; */
  font-family: futura-pt, sans-serif !important;
}

.base-modal-wrapper ::-webkit-scrollbar {
  width: 6px !important;
}

.base-modal-title-wrapper {
  display: flex;
  flex-direction: row;
  padding: 14px 24px 12px 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
}

.base-modal-title {
  font-size: 1.3rem !important;
  font-weight: 600 !important;
  color: #2938be !important;
  margin: 0 !important;
  /* font-family: "Open Sans", sans-serif !important; */
  /* font-family: "Montserrat", sans-serif !important ; */
  /* font-family: "Poppins", sans-serif; */
  font-family: futura-pt, sans-serif !important;
}

.base-modal-exit-image {
  width: 22px;
  margin-bottom: 6px;
}

/* .base-modal-exit {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background-color: #cbcbcb;
  position: relative;
  transition: all 200ms ease;
}

.base-modal-exit-1 {
  width: 3px;
  background-color: #fff;
  height: 14px;
  position: absolute;
  left: 9.5px;
  border-radius: 2px;
  top: 4px;
  transform: rotate(45deg);
}

.base-modal-exit-2 {
  width: 3px;
  background-color: #fff;
  height: 14px;
  position: absolute;
  left: 9.5px;
  border-radius: 2px;
  top: 4px;
  transform: rotate(-45deg);
} */

.base-modal-exit-image:hover {
  cursor: pointer;
  transition: all 200ms ease;
}

.base-modal-field {
  margin: 20px 28px 20px 38px;
}

.base-modal-fields {
  margin-top: 10px;
}

.base-modal-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0 24px;
  background-color: #eeeeee;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0 0 14px 14px;
}

.base-modal-button {
  width: 100%;
  max-width: 250px;
  margin: 24px 10px !important;
  border: none;
  border-radius: 22px !important;
  font-size: 1.12rem !important;
  padding: 6px;
  font-weight: 500;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.base-modal-button-image {
  margin: auto;
  width: 12px;
  margin-right: 10px;
  margin-bottom: 4px;
}

.mid-section {
  overflow: auto;
  overflow-y: inherit;
 /*  height: min-content; */
  height: 250vh;
  padding-bottom: 100px;
  margin-right: 4px !important;
}
