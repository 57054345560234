.user-task-box{
  background: white;
  margin: 20px auto;
  width: 60vw;
  min-width: 350px;
}

.user-task-reminder-header-box {
  display: flex;
  width: 100%;
}

.user-task-header1{
  width: 15%;
  text-align: center;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color: #61616f;
}

.user-task-header2{
  width: 50%;
  text-align: left;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color: #61616f;
}

.user-task-header3{
  width: 35%;
  text-align: center;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color: #61616f;
}

.user-task-body-box{}

.user-task-item-row{
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
}

.user-task-item1{
  width: 15%;
  text-align: center;
  background: #1EC0B1;
  align-self: center;
  border-radius: 7px;
  margin: 10px;
  font-family: 'Open Sans', sans-serif;
  color: white;
  font-weight: 600;
  font-size: 14px;
}

.user-task-item2{
  width: 70%;
  margin: 5px 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.user-task-item3{
  width: 35%;
  margin: 5px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
}
