.hollow-square-list-item {
  display: inline-flex;
  align-items: center;
  margin-right: 1em;
  /* height: 20px; */
}

.hollow-square-list-item:before {
  content: "\25A1";
  margin-right: 0.5em;
  font-size: 20px;
  margin-bottom: auto;
  margin-top: -5px;
}

.hollow-square-list-item span {
  display: block;
  white-space: normal;
  word-wrap: break-word;
  width: 400px;
}

@media (max-width: 768px) {
  .hollow-square-list-item span {
    width: 240px;
  }
}
