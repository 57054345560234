.console-pages .edu__expense_tracker_select {
  border: 0;
  border-bottom: 1px solid #e3e3e3;
  background: transparent;
  font-size: 16;
}

.console-pages .edu__expense_tracker_select:active {
  outline: none;
}

.console-pages .edu__expense_tracker_select:focus {
  outline: none;
}
