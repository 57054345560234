.console-pages .request-modal-cont {
  background-color: rgba(196, 196, 196, 0.32);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  font-family: "Montserrat";
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.01rem;
  line-height: 1.5;
}

/* border: 1px solid #999;
border: 1px solid rgba(0, 0, 0, 0.2); 
-webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
*/
.console-pages .request-modal {
  max-width: 500px;
  width: 100%;
  min-width: 300px;
  margin: auto;
  padding: 30px;
  z-index: 10001;
  color: black;

  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border-radius: 15px;
  outline: 0;
  -webkit-box-shadow: 0px 0px 15px 3px rgba(211, 211, 211, 0.75);
  box-shadow: 0px 0px 15px 3px rgba(211, 211, 211, 0.75);
  -ms-overflow-style: thin; /* IE and Edge Scrollbar on the right */
  scrollbar-width: thin; /* Firefox Scrollbar on the right */
  overflow: auto;
}

.console-pages .request-modal-cont ::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
}

.console-pages .request-modal-cont ::-webkit-scrollbar-thumb {
  background-color: #ddd;
  width: 6px;
  border-radius: 8px;
}

/* .console-pages ::-webkit-scrollbar {
  width: 6px;
} */

.console-pages .request-modal h3 {
  color: #31517d;
  font-size: 1.5rem !important;
  font-weight: 700 !important;
  letter-spacing: 0.3px !important;
  line-height: 1.3 !important;
  margin-bottom: 0.5rem;
}

.console-pages .request-modal label {
  margin: 14px 0 0;
  color: gray !important;
  padding-bottom: 3px;
  text-align: left !important;
  font-size: 1rem;
  font-weight: normal !important;
  margin-bottom: 8px;
}

.console-pages .request-modal .modal-footer button {
  margin: 14px 3px 0 !important;
  background-color: #3b4eff;
  border: 1px solid #3b4eff;
  width: 200px;
  border-radius: 7px !important;
  color: white;
  padding: 8px !important;
  font-weight: 600;
  box-shadow: 4px 4px 6px 0px rgba(191, 190, 190, 0.75);
}

.console-pages .request-modal .modal-footer button:hover {
  background-color: #2636cc;
}

.console-pages .request-modal .modal-footer .backbtn {
  background-color: #df4e4e;
  border: 1px solid #cc2727;
}

.console-pages .request-modal .modal-footer button.deletebtn:hover {
  background-color: #e22121;
}

.console-pages .request-modal .modal-footer {
  margin: 25px auto 0;
  padding: 0.75rem 5px 0;
}
