.user-task-box {
  background: white;
  margin: 20px auto;
  width: 60vw;
  min-width: 350px;
  padding-bottom: 10px;
}

.user-task-header-box {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.user-access-header {
  color: #61616f;
  margin: 0 15px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
}

.user-task-body-box {
}

.user-access-item-row {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.user-access-label {
  margin: 10px 15px;
  align-self: center;
  justify-self: center;
  text-align: center;
  min-width: fit-content;
  padding: 0 10px;
  width: 50%;
  font-family: "Open Sans", sans-serif;
  color: white;
  font-weight: 600;
  font-size: 14px;
  border-radius: 7px;
}

.user-access-label.color1 {
  background: #f0c454;
}

.user-access-label.color2 {
  background: #7B66FF;
}

.user-access-label.color3 {
  background: #1acdf5;
}

.user-access-item {
  margin: 10px 15px;
  align-self: center;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.user-access-role-dropdown {
  margin: 10px 15px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}