.invite-overview-wrapper {
  display: flex;
  margin-left: 61%;
  height: 0px;
  overflow: visible;
}

.invite-overview-content {
  font-size: 12px;
  width: max-content;
  padding-top: 20px;
}

.invite-overview-content div {
  display: flex;
  justify-content: space-between;
}

.invite-overview-content span {
  margin-right: 10px;
  line-height: 1.1;
}

@media screen and (max-width: 767px) {
  .invite-overview-wrapper {
      margin-left: 25% !important;
  }
}