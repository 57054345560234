.mark-paid-btn{
    padding: 5px !important;
    border-radius: 5px !important;
}


/* font-family: "Open Sans",sans-serif!important;
/* font-weight: 700!important; */
/* font-size: 10px!important; */
/* line-height: 14px!important; */
/* color: #fff!important; */
/* padding: 10px 25px!important; */
/* background: #9bb0eb!important; */
/* transition: all .15s ease!important; */
/* border: 0!important;
margin: 0 1px!important;  */
