.button-disabled {
  cursor: not-allowed !important;
}

/*
Communications Table Styling
*/
.comm-table {
  border-radius: 15px 0 0 0;
  z-index: 5;
  background-color: white;
  box-shadow: 0px 5px 6px 0px rgb(0 0 0 / 6%);
}

.comm-table::-webkit-scrollbar,
.comm-table .table-wrapper::-webkit-scrollbar,
.message-view::-webkit-scrollbar {
  width: 4px;
}

.comm-table::-webkit-scrollbar-track,
.comm-table .table-wrapper::-webkit-scrollbar-track,
.message-view::-webkit-scrollbar-track {
  background-color: transparent;
}

.comm-table table.table {
  font-style: "Montserrat";
  margin: 0;
}

.comm-table table.table tbody tr.comm-content {
  transition: background-color 0.15s, box-shadow 0.15s;
  cursor: pointer;
}

.comm-table table.table tbody tr.comm-content:hover {
  box-shadow: 0px 1px 4px 1px #ddd;
}

.comm-table table.table tbody tr.comm-content.selected {
  background-color: #f5f5f5;
  box-shadow: none;
}

.comm-table table.table tbody tr.comm-content .to-cell {
  padding-left: 0;
  padding-right: 0;
}

.comm-table table.table tbody tr.comm-content .date-cell {
  width: 60px;
  text-align: right;
  font-size: 12px;
  font-style: italic;
}

.comm-table table.table tbody tr.comm-content .pfp-cell {
  width: 50px;
}

.comm-table table.table tbody tr.comm-content .pfp-cell .pfp {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: auto;
}

.comm-table table.table td {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100px;
  white-space: nowrap;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.comm-table table.table td .ellipsis-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*
Message view styling
*/
.console-pages .box.comm-right {
  margin-right: 0;
}

.console-pages .box.comm-right .bgofbox {
  border-radius: 0 15px 0 0;
  overflow: hidden;
}

.message-top {
  background: #5bc8db !important;
  border-bottom: 1px #e5e5e5 solid;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
}

.message-heading {
  font-size: 16px;
  padding-left: 0;
  text-align: center;
  color: white;
  font-weight: 500;
}

.message-heading .secondary {
  font-style: italic;
  font-size: smaller;
  font-weight: normal;
}

.message-thread-view {
  padding: 4px 4px;
  overflow: auto;
  background-color: #f8f8f8;
}

.message-view {
  padding: 0px 10px;
  border-top: 1px solid rgb(229, 229, 229);
  background: linear-gradient(185deg, #ccc 0%, transparent 90px);
  /* background: linear-gradient(rgb(240, 240, 240) 0%, rgb(255, 255, 255) 30px); */
}

.message-view:hover {
  background: linear-gradient(185deg, #6af 0%, transparent 90px);
}

.message-view .body {
  border-radius: 10px;
  border: 1px solid #eee;
  margin: 5px 0 10px;
  padding: 10px;
  background-color: white;
}

.message-view .view-row {
  margin: 10px 0;
  display: flex;
  width: 100%;
  align-items: center;
}

.message-view .view-row .row-label {
  display: inline-block;
  min-width: 75px;
}

.message-view .view-row .row-content-emphasis {
   font-weight: bold;
}

.message-view .view-row .row-content-actionable {
  font-weight: normal;
  font-style: italic;
  cursor: pointer;
  font-size: smaller;
  line-height: 0.8;
  padding-top: 0.2em;
  border-bottom: 1px solid #00000000;  /*transparent*/
}

.message-view .view-row .row-content-actionable:hover {
  color: #3366ff;
  border-bottom: 1px dashed #3366ff;
  /* text-decoration: underline 0.1em dotted blue; */
  /* text-underline-offset: 0.1em; */
}

/***
.message-view .view-row .row-content-actionable::before {
  content: "(";
}

.message-view .view-row .row-content-actionable::after {
  content: ")";
}
***/

.message-view .view-row .pfp {
  width: 20px;
  height: 20px;
  margin-right: 4px;
  border-radius: 50%;
}

.pfp-alt {
  width: 25px;
  height: 25px;
  margin-right: 4px;
  border-radius: 50%;
}

.message-view .view-row .users-cont {
  display: flex;
  flex-wrap: wrap;
  padding: 4px;
  /* border: 1px solid gray; */
  border-radius: 10px;
  background-color: #fafafa;
}


.message-view .view-row .users-cont .user-bubble {
  background: #f0f0f0;
  border-radius: 50px;
  box-shadow: 1px 1px lightgrey;
  padding: 3px 8px;
  margin: 3px 5px 3px 0;
  height: min-content;
  display: flex;
  align-items: center;
}

/*
Search filter styling
*/

.search-container {
  display: inline-flex;
  flex: 1 1;
  border: none;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin-bottom: 1rem;
}

.search-container .search-bar {
  border: none;
  font-size: 13px;
  padding: 5px;
  outline: 0;
  background: white;
  flex: 1;
}

.rc-dropdown-menu > .rc-dropdown-menu-item {
  cursor: pointer;
}

