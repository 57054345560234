.console-pages .weekly-slots .table {
  position: relative;
  border-radius: 17px;
}

.console-pages .weekly-slots .table thead th {
  text-align: center;
}

.console-pages .weekly-slots .table thead th:first-child {
  border: 0;
}

.console-pages .weekly-slots .table tbody th,
.console-pages .weekly-slots .table tbody td {
  padding: 0 !important;
  position: relative;
  border: 1px solid #e0e0e0;
  border-top: 0;
  border-bottom: 2px solid #e0e0e0;
}

.console-pages .weekly-slots .table tbody th h2,
.console-pages .weekly-slots .table tbody td h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 60px;
  color: #fffdfd;
  margin: 0;
}

.console-pages .weekly-slots .table .timecell h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: black;
  line-height: 40px;
  text-align: center;
  width: 100%;
  display: block;
  margin: 0;
}

.console-pages .weekly-slots .table .timecell {
  background: #f5f5f5;
  flex-direction: column;
  justify-content: center;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
}

.console-pages .weekly-slots .table tbody td div:last-of-type {
  border-bottom: 0 !important;
}

.console-pages .weekly-slots .table tbody .hline {
  width: 100%;
  height: 1px;
  background-color: #e6e6e6;
  position: absolute;
  top: 50%;
}

.console-pages .weekly-slots .slotcircle {
  width: 50px;
  margin: auto auto;
  border: 1px solid #dcd6d6 !important;
  border-radius: 50%;
}

.console-pages .weekly-slots .space {
  height: 30px;
}

.console-pages .weekly-slots .table tbody th .vertical,
.console-pages .weekly-slots .table tbody td .vertical {
  position: relative;
  height: 120px;
  z-index: 0;
}

.console-pages .weekly-slots .table tbody th .vertical:before,
.console-pages .weekly-slots .table tbody td .vertical:before {
  content: "";
  position: absolute;
  border-bottom: 1px #e0e0e0 solid;
  height: 120px;
  width: 100%;
  transform: translateY(-50%);
}

.console-pages .weekly-slots .table tbody th .child,
.console-pages .weekly-slots .table tbody td .child {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}

.console-pages .weekly-slots .table tbody th .child .circle,
.console-pages .weekly-slots .table tbody td .child .circle {
  display: block;
  margin: auto;
}

.console-pages .weekly-slots .table tbody th .child p,
.console-pages .weekly-slots .table tbody td .child p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: #7c7c7c;
  margin: 0;
  text-align: center;
  margin-top: 5px;
}

.console-pages .weekly-slots .table tbody th:first-child {
  padding-left: 0;
  text-align: center;
}

.console-pages .weekly-slots .table tbody td:last-child {
  padding: 0;
  width: auto;
  border-right: 0;
}

.console-pages .weekly-slots .table tbody th:first-child {
  background: #8489ff;
  border-bottom: 2px solid #fff;
}

.console-pages .weekly-slots .table tbody tr:last-child th,
.console-pages .weekly-slots .table tbody tr:last-child td {
  border: 1px solid #e0e0e0;
  border-bottom: 0;
}

.console-pages .weekly-slots .table tbody tr:last-child th:first-child {
  border: 0;
}

.console-pages .weekly-slots .table tbody tr:nth-last-child(-n + 3) th,
.console-pages .weekly-slots .table tbody tr:nth-last-child(-n + 3) td {
  border-bottom: 0;
}

.console-pages
  .weekly-slots
  .table
  tbody
  tr:nth-last-child(-n + 3)
  th:first-child {
  border: 0;
}

.weekly-slots .circle {
  background: #b7b7b7;
  display: inline-block;
  margin: 0 1em 1em 0;
  width: 54px;
  height: 54px;
}

.weekly-slots .full {
  border-radius: 54px;
}

.weekly-slots .top,
.weekly-slots .bottom {
  height: 27px;
  width: 54px;
}

.weekly-slots .right,
.weekly-slots .left {
  height: 54px;
  width: 27px;
}

.weekly-slots .top {
  border-top-left-radius: 54px;
  border-top-right-radius: 54px;
}

.weekly-slots .right {
  border-bottom-right-radius: 54px;
  border-top-right-radius: 54px;
}

.weekly-slots .bottom {
  border-bottom-left-radius: 54px;
  border-bottom-right-radius: 54px;
}

.weekly-slots .left {
  border-bottom-left-radius: 54px;
  border-top-left-radius: 54px;
}

.weekly-slots .Pending {
  background-color: #f9a41e;
}

.weekly-slots .Expiring {
  background-color: #ff6763;
}

.weekly-slots .Active {
  background-color: #1bc88a;
}

.weekly-slots .empty {
  background-color: white;
}

.weekly-slots .empty-bottom-border {
  border: 1px solid black;
  position: absolute;
  width: 54px;
  height: 27px;
  border-bottom-left-radius: 54px;
  border-bottom-right-radius: 54px;
}

.weekly-slots .empty-top-border {
  border: 1px solid black;
  position: absolute;
  width: 54px;
  height: 27px;
  border-top-left-radius: 54px;
  border-top-right-radius: 54px;
}

.weekly-slots .empty-border {
  border: 1px solid black;
  position: absolute;
  width: 54px;
  height: 54px;
  border-radius: 54px;
}
