.img-container{
    width: 100%;
    height: 300px;
}

.img-preview {
    width: 200px;
    height: 200px;
    float: left;
    margin-left: 10px;
}

.cropper-crop-box, .cropper-view-box {
    border-radius: 10px;
}

.cropper-view-box {
    box-shadow: 0 0 0 1px #39f;
    outline: 0;
}