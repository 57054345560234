.console-pages .ms-sub {
  padding: 0 8px;
  height: 0;
  overflow: hidden;
  border-bottom: 4px solid #eeecec;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}

.console-pages .ms-sub-show {
  height: 150px !important;
  overflow: auto;
  white-space: pre-wrap;
}

.console-pages .milestones {
  display: flex;
  flex-wrap: wrap;
}

.console-pages .milestones .leftsidebox {
  flex: 3 3;
  background: #f4f6ff;
  min-width: 300px;
}

.console-pages .milestones .right-cont {
  flex: 4 4 20em;
  min-width: 500px;
}

@media (max-width: 850px) {
  .console-pages .milestones .right-cont {
    flex: 4 4 20em;
    min-width: 400px;
  }

  .console-pages .milestones .leftsidebox .box1 img {
    max-width: 45px !important;
  }

  .console-pages .milestones .leftsidebox .box1 .firstline {
    padding: 10px 40px !important;
  }
}

.console-pages .milestones .leftsidebox h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 34px;
  color: #042470;
  margin-bottom: 35px;
}

.console-pages .milestones .leftsidebox p {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
  letter-spacing: 0.6px;
  margin-bottom: 40px;
}

.console-pages .milestones .leftsidebox .box1 {
  border-radius: 26px;
  overflow: hidden;
  background: #ffffff;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
}

.console-pages .milestones .leftsidebox .box1 .col-md-4 img {
  width: 60px;
}

.console-pages .milestones .leftsidebox .box1 .firstline {
  padding: 15px 10px;
  display: block;
  border-bottom: 1px solid #e2e2e2;
}

.console-pages .milestones .leftsidebox .box1 .firstline .ssline {
  width: 100%;
  background: #e0e0e0;
  height: 12px;
  display: block;
  margin-top: 20px;
}

.console-pages .milestones .leftsidebox .box1 .firstline .ssline .completed {
  height: 12px;
  width: 35%;
  background: #53c79d;
}

.console-pages .milestones .leftsidebox .box1 .firstline p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #717171;
  margin: 0;
  margin-top: 5px;
  text-align: right;
  width: 100%;
}

.console-pages .milestones .leftsidebox .box1 .firstline .lock {
  display: block;
  margin: auto;
  margin-top: 20px;
}

.console-pages .milestones .leftsidebox .box1 .firstline:last-child {
  border: 0;
}

.console-pages .milestones .leftsidebox .box2 {
  border-radius: 26px;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  overflow: hidden;
  background: #ffffff;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
}

.console-pages .milestones .leftsidebox .box2 h3 {
  background: #7ea0ff;
  background: -moz-linear-gradient(left, #7ea0ff 0%, #915aff 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #7ea0ff),
    color-stop(100%, #915aff)
  );
  background: -webkit-linear-gradient(left, #7ea0ff 0%, #915aff 100%);
  background: -o-linear-gradient(left, #7ea0ff 0%, #915aff 100%);
  background: -ms-linear-gradient(left, #7ea0ff 0%, #915aff 100%);
  background: linear-gradient(to right, #7ea0ff 0%, #915aff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7ea0ff', endColorstr='#915aff', GradientType=1 );
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 25px;
  color: #fff;
  padding-top: 7px;
  padding-bottom: 7px;
  margin: 0;
}

.console-pages .milestones .leftsidebox .box2 p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  margin: 0;
  text-align: center;
  padding-top: 26px;
  padding-bottom: 30px;
  position: relative;
  border-bottom: 1px solid #e2e2e2;
}

.console-pages .milestones .leftsidebox .box2 p .check {
  margin-left: -120px;
  position: absolute;
  margin-top: -5px;
}

.console-pages .milestones .leftsidebox .box2 p .aboutit {
  margin-left: 60px;
  position: absolute;
  top: 27px;
}

.console-pages .milestones .leftsidebox .box2 p span {
  width: 110px;
  display: block;
  text-align: left;
  margin: auto;
}

.console-pages .milestones .leftsidebox .box2 p:last-child {
  border: 0;
}

.console-pages .milestones .bigbox {
  width: 100%;
  max-width: 670px;
  margin: auto;
  background: #fff;
  margin-top: 60px;
  position: relative;
  margin-bottom: 40px;
}

.console-pages .milestones .bigbox .line {
  width: 100%;
  text-align: center;
  position: relative;
  margin-bottom: 40px;
}

.console-pages .milestones .bigbox .line svg {
  margin-top: -35px;
  z-index: 999;
  overflow: visible;
}

.console-pages .milestones .bigbox .line path {
  stroke: #eceffa;
  stroke-width: 4px;
  stroke-linejoin: miter;
}

.console-pages .milestones .bigbox .line .h-line {
  width: 100%;
  height: 14px;
  /* position: absolute; */
  margin-top: -43px;
  z-index: 900;
}

.console-pages .milestones .bigbox p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 17px;
  color: #4a4a4a;
  padding-top: 22px;
  padding-bottom: 22px;
  padding-left: 35%;
  /* border-bottom: 4px solid #eeecec; */
  position: relative;
  margin: 0;
}

.console-pages .milestones .bigbox p img {
  position: absolute;
  margin-left: -80px;
}

.console-pages .milestones .bigbox p:last-child {
  border-bottom: 0;
}

.console-pages .milestones .bigbox .active {
  background: #f5f5f5;
  font-weight: 700;
}
