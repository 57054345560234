.console-pages .paymentmethod {
  padding-left: 0px;
  padding-right: 0;
}

.console-pages .paymentmethod h3 {
  font-size: 13px;
  line-height: 34px !important;
  margin-bottom: 0;
}

.console-pages .paymentmethod .cont .paymentbox {
  background: #ffffff;
  width: 100%;
  border-radius: 8px;
  padding: 25px 0;
  -webkit-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
}

.console-pages .paymentmethod .cont .paymentbox h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  padding-left: 100px;
  margin-bottom: 40px;
}

.console-pages .paymentmethod .cont .paymentbox h2 span {
  color: #fff;
  background: #7ea1ff;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  position: absolute;
  border-radius: 50%;
  margin-top: -5px;
  margin-left: -60px;
}

.console-pages .paymentmethod .cont .paymentbox .method-button {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  padding-left: 100px;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  display: block;
  border-bottom: 1px solid #bcbcbc;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
  background-color: transparent;
  border-top: none;
  border-right: none;
  border-left: none;
}

.console-pages .paymentmethod .cont .paymentbox .method-button p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #7b7b7b;
  margin: 0;
}

.console-pages .paymentmethod .cont .paymentbox .method-button .leftcircle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: #000000 solid 2px;
  position: absolute;
  margin-top: 3px;
  margin-left: -60px;
}

.console-pages
  .paymentmethod
  .cont
  .paymentbox
  .method-button
  .leftcircle
  .activecircle {
  width: 10px;
  height: 10px;
  background: #000;
  border-radius: 50%;
  margin-top: 2.5px;
  margin-left: 2.5px;
  display: none;
}

.console-pages
  .paymentmethod
  .cont
  .paymentbox
  .method-button
  .active
  .activecircle {
  display: block;
}

.console-pages .paymentmethod .cont .paymentbox .method-button:hover {
  text-decoration: none;
  opacity: 0.7;
}

.console-pages .paymentmethod .cont .paymentbox .leftsidebox {
  border-right: 1px solid #bcbcbc;
  height: 100%;
}

.console-pages .paymentmethod .cont .paymentbox .leftsidebox .method-button {
  text-align: left;
}

.console-pages .paymentmethod .cont .paymentbox .leftsidebox .contofinfo {
  display: block;
}

.console-pages .paymentmethod .cont .paymentbox .rightside {
  padding-left: 40px;
  padding-right: 40px;
  margin: auto;
  display: block;
}

.console-pages .paymentmethod .cont .paymentbox .rightside input:focus,
.console-pages .paymentmethod .cont .paymentbox .rightside select:focus {
  outline: none;
  box-shadow: 0px 0px 2px #0066ff;
}

.console-pages .paymentmethod .cont .paymentbox .rightside input,
.console-pages .paymentmethod .cont .paymentbox .rightside select {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #ccc;
  width: 100%;
  padding: 2px 5px;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  direction: ltr;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  pointer-events: all;
  border-radius: 5px;
}

.console-pages .paymentmethod .cont .paymentbox .rightside .row {
  margin-bottom: 14px;
}

.console-pages .paymentmethod .cont .paymentbox .rightside h2 {
  padding-left: 60px;
  text-align: left;
}

.console-pages .paymentmethod .cont .paymentbox .rightside img {
  max-width: 100%;
  margin: auto auto;
  display: block;
}

.console-pages .paymentmethod .cont .paymentbox .rightside .contofinfo {
  display: inline-block;
  width: 100%;
}

.console-pages .paymentmethod .cont .buttons {
  text-align: right;
  margin-bottom: 50px;
  margin-top: 20px;
}

.console-pages .paymentmethod .cont .buttons .savebtn {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  color: #ffffff;
  display: inline-block;
  background: #7ea1ff;
  border: 1px solid #7ea1ff;
  padding: 11px 18px;
  border-radius: 5px;
}

.console-pages .paymentmethod .cont .buttons button:hover {
  text-decoration: none;
  opacity: 0.7;
}

@media (max-width: 992px) {
  .console-pages .paymentmethod .cont .paymentbox .rightside h2 {
    margin-top: 40px;
  }

  .console-pages .paymentmethod .cont .paymentbox .rightside {
    text-align: left;
  }

  .console-pages .paymentmethod .cont .paymentbox .leftsidebox {
    border-right: 0;
  }
}

/*************************************************
	        @MEDIA(MAX-WIDTH: 1570PX)
*************************************************/
@media (max-width: 1570px) {
  .console-pages .paymentmethod .cont .paymentbox h2 {
    padding-left: 80px;
    font-size: 17px;
  }

  .console-pages .paymentmethod .cont .paymentbox .method-button {
    font-size: 17px;
    padding-left: 80px;
  }

  .console-pages .paymentmethod .cont .paymentbox .method-button p {
    font-size: 14px;
  }

  .console-pages .paymentmethod .cont .paymentbox .rightside {
    padding-left: 20px;
    padding-right: 20px;
  }
}
