.large-cards {
  display: flex;
  flex-wrap: wrap;
  margin-left: 40px;
  margin-bottom: 30px;
  /* width: 750px; */
}

.email-card {
  height: 290px;
  width: 380px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 15px 0 rgba(204, 204, 204, 0.5);
  margin-right: 35px;
}

.customization-card {
  height: 290px;
  width: 380px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 15px 0 rgba(204, 204, 204, 0.5);
  margin-right: 35px;
  margin-bottom: 25px;
}

.order-card {
  height: 290px;
  width: 380px;
  background: #fff;
  border-radius: 10px;
  margin-right: 35px;
  margin-bottom: 25px;
  box-shadow: 0 2px 15px 0 rgba(204, 204, 204, 0.5);
}

.header-section {
  padding-top: 12px;
  padding-left: 15px;
  padding-bottom: 3px;
  border-bottom: 1px solid gainsboro;
}

.card-titles {
  font-family: "Open Sans", sans-serif;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #050982;
}

.row-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 20px;
  /* flex-wrap: wrap; */
  /* margin-right: -0.75rem;
    margin-left: -0.75rem; */
  /* padding: 12px 20px 0;
    margin-right: -0.75rem;
    margin-left: -0.75rem; */
  font-size: 11px !important;
}

.label-section {
}

.input-section {
  width: 55%;
}

.label-title {
  font-family: "Montserrat", sans-serif;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 17px;
  color: #696969;
  /* padding: 30px 0px 0px 25px; */
  padding: 8px 0 0;
  width: 127px;
}

.email-description-section {
  padding: 10px 15px;
}

.email-description {
  color: #898da0;
  font-size: 12px !important;
  font-family: Montserrat !important;
  font-weight: 500;
}

.email-input-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  width: 90%;
}

.email-label {
  font-family: "Open Sans", sans-serif;
  margin-right: 7px;
  font-size: 16px;
  font-weight: 600;
  color: #050982;
}

.email-input {
  margin-right: 7px !important;
  border: 1px solid lightgrey;
  padding: 3px 7px;
  border-radius: 7px;
}

.email-input::placeholder {
  color: lightgrey;
}

.email-pencil {
  cursor: pointer;
}

.set-email {
  border: none;
  color: #fff;
  background-color: #4b60d7;
  padding: 3px 8px;
  border-radius: 5px !important;
}

.email-lower-section {
  display: flex;
  padding-left: 45px;
  align-items: center;
}

.email-button {
  margin-left: 30px;
}

.order-title {
  font-family: Montserrat !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.order-row {
  display: flex;
  padding-left: 15px;
  justify-content: flex-start;
  align-items: center !important;
}

.order-label {
  font-size: 10px !important;
  font-family: Montserrat !important;
  font-weight: 500 !important;
  margin-bottom: 0 !important;
}

.order-input-area {
  margin-left: 30px;
  margin-bottom: 15px;
}

.order-input {
  border: none;
  border-bottom: 1px solid #898da0;
  font-size: 11px !important;
}

.order-text {
  color: #898da0;
  font-family: Montserrat !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  padding-left: 15px;
  margin-bottom: 0px !important;
}

.integrations-title {
  color: #7f7f7f;
  font-family: Montserrat;
  font-weight: 600 !important;
  font-size: 20px !important;
}

.intergrations-card-container {
  display: flex;
  margin-left: 40px;
}

.intergrations-card {
  width: 380px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 15px 0 rgba(204, 204, 204, 0.5);
  display: block;
  padding: 15px 15px;
  margin: 0px 0px 20px;
  height: 150px;
}

.intergrations-card:first-child {
  margin-right: 35px;
}

.integrations-card-section {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.integrations-card-logo {
  height: 40px;
  width: 40px;
  object-fit: cover;
}

.integrations-button-section {
  margin-left: 55px;
  margin-top: 5px;
}

.integrations-button {
  background: #ff6663;
  color: #fff;
  border: none;
  width: 150px;
  height: 25px;
  cursor: pointer;
  user-select: none;
}

.disabled-integrations-button {
  background: lightgrey;
  color: #fff;
  border: none;
  width: 150px;
  height: 25px;
  cursor: not-allowed;
  user-select: none;
}

/* Tablets */
@media only screen and (max-width: 960px) {
  .large-cards {
    margin-left: 20px; /* changed */
    flex-wrap: wrap;
  }

  .email-card {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .customization-card {
    margin-right: 10px;
  }

  .order-card {
    height: 230px;
    width: 350px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 15px 0 rgba(204, 204, 204, 0.5);
  }

  .intergrations-card-container {
    margin-left: 10px; /* changed */
  }
}

/* Phones */
@media only screen and (max-width: 767px) {
  .large-cards {
    display: block;
    margin-left: 20px;
  }

  .email-card {
    margin-bottom: 20px;
    width: 335px;
  }

  .customization-card {
    margin-bottom: 20px;
    width: 335px;
  }

  .order-card {
    width: 335px;
  }

  .intergrations-card-container {
    display: block;
    margin-left: 10px;
  }
}
