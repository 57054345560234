.cont.heading-wrapper {
  background: linear-gradient(to right, #80a1ff 0%, #2453bb 100%);
}
.cont > .page-heading{
  padding-top: 22px;
  padding-bottom: 22px;
  margin-top: 0px !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 700 !important;
  font-size: 23px !important;
  line-height: 34px !important;
  color: #042470 !important;
  text-align: left !important;
}
.dropdown-label {
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #042470 !important;
}

.org-dropdown {
  margin-bottom: 22px !important;
  width: 250px !important;
}

.dropdown-wrapper {
  padding-left: 24px !important;
}