.events-title-header {
  position: absolute;
  top: -1.6vh;
  left: 4%;
  z-index: 100;
}

.label-names {
  font-size: 14px !important;
  color: #535353 !important;
}

.sub-labels {
  font-size: 14px !important;
  color: #535353 !important;
  font-weight: 500 !important;
}

.input-fields {
  font-family: "Open Sans", sans-serif !important;
  font-size: 13px !important;
  color: #696969 !important;
  /* color: #4b1f1f !important; */
  border: 1px solid #e9e6e6;
  padding: 7px 5px !important;
  /* padding-left: 10px !important; */
}

.input-fields::placeholder {
  color: #beb8b8 !important;
}

.input-fields-no-border {
  font-family: "Open Sans", sans-serif !important;
  font-size: 13px !important;
  color: #696969 !important;
  /* color: #4b1f1f !important; */
  /* border: 1px solid #e9e6e6 !important;
    padding: 7px 5px !important; */
  /* padding-left: 10px !important; */
}

.input-text-fields {
  font-family: "Open Sans", sans-serif !important;
  font-size: 13px !important;
  color: #696969;
  /* color: #4b1f1f !important; */
  border: 1px solid #e9e6e6 !important;
  padding: 5px 10px !important;
  /* padding-left: 10px !important; */
}

.addNew-btn {
  font-family: "Open Sans", sans-serif !important;
  color: #fff !important;
  background-color: #ff6663 !important;
  border-color: #ff6663 !important;
  font-size: 13px !important;
  padding: 5px 8px !important;
}

.center-box {
  display: flex;
  justify-content: center;
}

.custom-width-box {
  max-width: 700px !important;
}

.ticket-status {
  border: 1px solid #7ea1ff;
  padding: 4px 6px;
  color: #7ea1ff;
  background-color: #fff;
  border-radius: 15px;
  margin-right: 10px;
  font-size: 13px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ticket-status-inverse {
  border: 1px solid #7ea1ff;
  padding: 4px 6px;
  color: #fff;
  background-color: #7ea1ff;
  border-radius: 15px;
  margin-right: 10px;
  font-size: 13px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-tags {
  border: 1px solid #ff6663;
  padding: 6px 14px;
  color: #ff6663;
  background-color: white;
  border-radius: 2px;
  margin-right: 10px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  font-family: "Open Sans", sans-serif !important;
}

.button-tags:hover {
  color: white;
  background-color: #ff6663;
}

.sub-row {
  flex-wrap: nowrap !important;
}

.padding-zero {
  /*Program section 2*/
  padding-right: 0rem !important;
  padding-left: 0rem !important;
  justify-content: center !important;
}

.no-border {
  border: 0 !important;
}

.switch-tooltip-group {
  display: flex;
  align-items: center;
}


.listing-step-btn-active{
  font-size: 26px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid #0431B0;
    color: white;
    border-radius: 50%;
    padding: 22px;
    background: #0431B0;
    margin-left: 15px;
    cursor: pointer;
}

.listing-step-btn-inactive{
  font-size: 26px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid #DAE3F3;
  color: #DAE3F3;
  border-radius: 50%;
  padding: 22px;
  background: white;
  margin-left: 15px;
  cursor: pointer;

}

#buttons-wrapper {
  margin-bottom: 0px !important;
  margin-top: 45px !important;
}

/* Tablets */
@media only screen and (max-width: 1097px) {
  .events-title-header {
    position: absolute;
    top: -1vh;
    left: 10%;
    z-index: 100;
  }
}

/* Phones */
@media only screen and (max-width: 767px) {
  .events-title-header {
    position: absolute;
    top: -1vh;
    left: 15%;
    z-index: 100;
  }

  .button-tags {
    margin-bottom: 10px !important;
  }

  .custom-width-box {
    max-width: 370px !important;
  }

  #buttons-wrapper {
    margin-bottom: inherit !important;
  }
}
