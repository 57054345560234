.price-card {
  display: flex;
  flex-direction: column;
  height: 60vh;
  min-height: 438px;
  width: 16vw;
  min-width: 245px;
  background-color: white;
  border-radius: 8px;
  border-color: #29465B;
  border-width: 1px;
  border-style: solid;
  padding: 10px;
  transition: all 200ms ease-out;
  margin-left: 10.5px;
  margin-right: 10.5px;
}

.inactive-card {
  background-color: rgb(241 241 241) !important;
}

.price-card:hover {
  box-shadow: 0 0 8px 2px #29465B;
}

.price-card-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: -5px;
}
.drag-arrows {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  border-width: 0px;
  padding-top: 2px;
  max-height: 10px;
  max-width: 15px;
}

.price-card-content-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 10px;
  padding-right: 10px;
  color: #6B6C8A;
}

.price-card-title {
  font-family: "Open Sans";
  font-size: small;
  font-weight: bold !important;
  color: #6B6C8A;
  text-align: center;
}

.price-card-top {
  display: flex;
  flex-direction: column;
  justify-content: unset !important;
  align-items: center;
  color: inherit;
  margin-bottom: 5px;
}

.price {
  margin-top: 0 !important;
  margin-bottom: 5px !important;
  font-size: 35px !important;
  color: inherit !important;
  align-self: auto !important;
}

.recurring-plans {
  margin-top: -5px !important;
  font-weight: 800;
  font-size: 14px !important;
}

.plan-timing {
  margin-bottom: 10px;
  font-size: 12px;
}

.plan-description {
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: black;
  font-family: "Inter", sans-serif;
  font-style: italic;
  padding-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Recurring-Free-logo {
  margin-top: 25px;
}

.plan-logo {
  display: block;
  height: 50px;
  width: 50px;
}

.price-card-middle {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 13px;
  margin-top: auto;
}

.no-description-plan {
  margin-top: 27.5px;
}

.price-card-middle > ul {
  margin-bottom: -3px !important;
}

.price-card-middle > ul > li {
  padding-bottom: 3px;
}

.price-card-bottom {
  margin-top: auto;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.plan-enrollment-info {
  text-align: center;
  padding-bottom: 15px;
  font-size: 13px;
}

.edit-button {
  background-color: #21005D;
  color: white;
  font-size: 15px !important;
  font-family: "Open Sans" !important;
  border-radius: 8px !important;
  border-width: 0px;
  min-height: 35px;
  margin: 0 12.5px !important;
}

.disabled-button {
  background-color: #645B75;
}

.date-selection {
  display: flex;
  align-items: center;
  width: fit-content;
  align-self: center;
  margin-top: auto;
}
.proration-selection {
  display: flex;
  align-items: center;
  width: fit-content;
  align-self: center;
  margin-top: auto;
}
.installments-selection {
  display: flex;
  align-items: center;
  width: fit-content;
  align-self: center;
  margin-top: auto;
}

.addOnSublist {
  font-size: 10px;
  padding-left: 15px;
  list-style-type: decimal;
}
