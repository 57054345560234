.search-category {
  padding: 0px 20px;
  font-weight: bold;
  text-decoration: underline;
}

.search-category-user,
.search-category-manage,
.search-category-listings {
  padding: 10px 20px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
}
