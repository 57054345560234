.promobanner {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100px;
  background-color: rgb(235, 150, 39);
  width: 100%;
  z-index: 30;
  border-top: 1px solid #dbaa3f;
  color: #fff;
}
.promobanner .containerpromo {
  position: relative;
  height: 100%;
  width: 100%;
  margin-top: 10px;
}
.promobanner .containerpromo h3 {
  margin: 0;
  color: #000000;
}
.promobanner .containerpromo p {
  text-align: center;
}

.link-header {
  color: rgb(129, 129, 249) !important;
  font-weight: 400 !important;
  cursor: pointer;
}
.link-header.selection {
  color: rgb(56, 56, 230) !important;
  font-weight: 400 !important;
  cursor: pointer;
  text-decoration: underline !important;
}
.link-header:hover {
  text-decoration: underline;
}
.underline:hover {
  text-decoration: underline;
}
.smalltext {
  text-align: center;
  font-size: 11px;
  color: rgb(117, 108, 108);
  margin-bottom: 20px;
}
@media only screen and (max-width: 500px) {
  .promobanner {
    height: auto;
    width: 100%;
  }
}
.gra-blue-purple {
  background: #7ea0ff;
  background: -moz-linear-gradient(left, #7ea0ff 0%, #915aff 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #7ea0ff),
    color-stop(100%, #915aff)
  );
  background: -webkit-linear-gradient(left, #7ea0ff 0%, #915aff 100%);
  background: -o-linear-gradient(left, #7ea0ff 0%, #915aff 100%);
  background: -ms-linear-gradient(left, #7ea0ff 0%, #915aff 100%);
  background: linear-gradient(to right, #7ea0ff 0%, #915aff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7ea0ff', endColorstr='#915aff', GradientType=1 );
}

.gra-yellow-pink {
  background: #f5c68c;
  background: -moz-linear-gradient(left, #f5c68c 0%, #fd84c0 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #f5c68c),
    color-stop(100%, #fd84c0)
  );
  background: -webkit-linear-gradient(left, #f5c68c 0%, #fd84c0 100%);
  background: -o-linear-gradient(left, #f5c68c 0%, #fd84c0 100%);
  background: -ms-linear-gradient(left, #f5c68c 0%, #fd84c0 100%);
  background: linear-gradient(to right, #f5c68c 0%, #fd84c0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5c68c', endColorstr='#fd84c0', GradientType=1 );
}

.console-pages .gra-purple-blue {
  background: #ac93ff;
  background: -moz-linear-gradient(left, #ac93ff 0%, #98dfe8 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #ac93ff),
    color-stop(100%, #98dfe8)
  );
  background: -webkit-linear-gradient(left, #ac93ff 0%, #98dfe8 100%);
  background: -o-linear-gradient(left, #ac93ff 0%, #98dfe8 100%);
  background: -ms-linear-gradient(left, #ac93ff 0%, #98dfe8 100%);
  background: linear-gradient(to right, #ac93ff 0%, #98dfe8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ac93ff', endColorstr='#98dfe8', GradientType=1 );
}

.console-pages .gra-teal-green {
  background: #2cd6d6;
  background: -moz-linear-gradient(left, #2cd6d6 0%, #aee85e 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #2cd6d6),
    color-stop(100%, #aee85e)
  );
  background: -webkit-linear-gradient(left, #2cd6d6 0%, #aee85e 100%);
  background: -o-linear-gradient(left, #2cd6d6 0%, #aee85e 100%);
  background: -ms-linear-gradient(left, #2cd6d6 0%, #aee85e 100%);
  background: linear-gradient(to right, #2cd6d6 0%, #aee85e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2cd6d6', endColorstr='#aee85e', GradientType=1 );
}

.console-pages .gra-yellow-orange {
  background: #ffd446;
  background: -moz-linear-gradient(left, #ffd446 0%, #fc8c7e 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #ffd446),
    color-stop(100%, #fc8c7e)
  );
  background: -webkit-linear-gradient(left, #ffd446 0%, #fc8c7e 100%);
  background: -o-linear-gradient(left, #ffd446 0%, #fc8c7e 100%);
  background: -ms-linear-gradient(left, #ffd446 0%, #fc8c7e 100%);
  background: linear-gradient(to right, #ffd446 0%, #fc8c7e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd446', endColorstr='#fc8c7e', GradientType=1 );
}

.ui.selection.dropdown .menu > .item {
  display: flex !important;
}
.ui.dropdown .menu > .item > .image,
.ui.dropdown .menu > .item > img {
  max-width: 20% !important;
  height: 30px !important;
  border-radius: 5px !important;
}
