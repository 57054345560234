#attendance-calendar {
  width: 100%;
  display: block;
}

#attendance-calendar ul {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

#attendance-calendar ul li {
  display: inline-block;
  margin-bottom: 9px;
  margin-right: 15px;
  z-index: 0;
}

#attendance-calendar ul li a {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #a3a3a3;
  padding: 12px 20px;
  background: #fff;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);
  position: relative;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

#attendance-calendar ul li a:hover {
  text-decoration: none;
  color: #000;
}

#attendance-calendar ul li .foractive {
  position: absolute;
  right: -14px;
  bottom: 0px;
  position: absolute;
  right: -34px;
  display: none;
}

#attendance-calendar ul .active {
  margin-right: 21px;
}

#attendance-calendar ul .active a {
  padding: 20px 20px;
  margin-top: -10px;
  position: relative;
  display: table;
  margin-bottom: -25px;
  color: #000;
}

#attendance-calendar ul .active .foractive {
  display: block;
}

#attendance-calendar .table {
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.05);
  z-index: 1;
  position: relative;
  border-radius: 17px;
}

#attendance-calendar .table thead {
  background: #7ea0ff;
  background: -moz-linear-gradient(left, #7ea0ff 0%, #915aff 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #7ea0ff),
    color-stop(100%, #915aff)
  );
  background: -webkit-linear-gradient(left, #7ea0ff 0%, #915aff 100%);
  background: -o-linear-gradient(left, #7ea0ff 0%, #915aff 100%);
  background: -ms-linear-gradient(left, #7ea0ff 0%, #915aff 100%);
  background: linear-gradient(to right, #7ea0ff 0%, #915aff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7ea0ff', endColorstr='#915aff', GradientType=1 );
}

#attendance-calendar .table thead th {
  border: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  color: #ffffff;
  text-align: center;
  padding: 20px 10px;
  white-space: nowrap;
}

#attendance-calendar .table tbody {
  background: #fff;
}

#attendance-calendar .table tbody th,
#attendance-calendar .table tbody td {
  border: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  padding: 0;
  position: relative;
  border: 1px solid #e0e0e0;
}

#attendance-calendar .table tbody th h2,
#attendance-calendar .table tbody td h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 13px;
  color: #606060;
  margin: 0;
  padding: 0 15px;
  align-self: center;
}

#attendance-calendar .table tbody th .vertical,
#attendance-calendar .table tbody td .vertical {
  position: relative;
  height: 90px;
  z-index: 0;
}

#attendance-calendar .table tbody th .child,
#attendance-calendar .table tbody td .child {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}

#attendance-calendar .table tbody th .child img,
#attendance-calendar .table tbody td .child img {
  display: block;
  margin: auto;
}

#attendance-calendar .table tbody th .child p,
#attendance-calendar .table tbody td .child p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: #7c7c7c;
  margin: 0;
  text-align: center;
  margin-top: 5px;
}

#attendance-calendar .table tbody th button,
#attendance-calendar .table tbody td button {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  color: #fff;
  padding: 10px 25px;
  background: #9bb0eb;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

#attendance-calendar .table tbody th button:hover,
#attendance-calendar .table tbody td button:hover {
  background: #915aff;
  text-decoration: none;
}

#attendance-calendar .table tbody th:first-child {
  border: 0;
  display: flex;
  height: 91px;
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
  z-index: 5000;
}

#attendance-calendar .table tbody tr:last-child th,
#attendance-calendar .table tbody tr:last-child td {
  border: 1px solid #e0e0e0;
  border-bottom: 0;
}

#attendance-calendar .table tbody tr:last-child th:first-child {
  border: 0;
  border-top: 1px solid #e0e0e0;
  z-index: 5000;
}

@media (max-width: 825px) {
  #attendance-calendar .table thead th {
    padding: 20px 10px;
    font-size: 10px;
  }
  #attendance-calendar .table tbody th h2 {
    font-size: 10px;
  }
}

#attendance-calendar .table-responsive {
  border-radius: 17px;
}

#attendance-calendar .table {
  margin-bottom: 0;
}

.attendance-red {
  color: #ff706c;
}

.attendance-blue {
  color: #8dacff;
}

.attendance-orange {
  color: #fab240;
}

@media (min-width: 600px) {
  .console-pages .table-responsive {
    overflow-x: hidden !important;
  }
}

.cell-lines {
  background-image: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 6px,
    #dddbf7 3px,
    #dddbf7 8px
  );
}
