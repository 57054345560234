.calendar_width {
  width: 414px;
}

.react-calendar__tile_dashboard {
  flex-basis: 7.2857% !important;
  text-align: center !important;
  padding: 0.75em 0.5em !important;
  background: none;
  margin: 0.4% 3.5% !important;
}

.react-calendar__month-view__days .highlighted.scheduled {
  background-color: #5cc8dc;
  border-radius: 50%;
}

.react-calendar__month-view__days .highlighted.reminder {
  background-color: #bbb;
  border-radius: 50%;
}

.react-calendar__month-view__weekdays__weekday_dashboard {
  margin-left: 0px;
  color: #9dbfff !important;
  font-size: 11px !important;
  margin-bottom: 10px;
}

/* Phones */
@media only screen and (max-width: 767px) {
  .calendar_width {
    width: 340px;
  }
}
