@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap");

:root {
  --body-bg: #f5f8ff;
  --box-bg: #fff;
  --input-bg: #f5f8ff;
  --txt-color: #2f2d2f;
  --txt-second-color: #ccc;
  --border-color: #4267b2;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.milestone-file-upload-box {
  background-color: var(--box-bg);
  padding: 30px;
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  /* margin-right: ; */
  /* box-shadow: var(--box-shadow); */
  height: 350px;
  margin-right: -25px;
}

#show-scroll-bar {
  padding-top: 25px !important;
  padding-bottom: 10px !important;
}

.milestone-file-upload-box::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
}
.milestone-file-upload-box::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Set the color of the thumb */
  border-radius: 5px; /* Round the edges of the thumb */
}
.milestone-file-upload-box::-webkit-scrollbar-thumb:hover {
  background-color: #aaa; /* Change the color of the thumb when hovered */
}
.milestone-file-upload-box::-webkit-scrollbar-track {
  background-color: #fff; /* Set the color of the track */
}

.milestone-upload-modal-cont {
  display: flex;
  align-items: center;
  justify-content: center;
}

.milestone-file-upload-header {
  margin-bottom: 30px;
  text-align: center;
  position: relative;
}

.milestone-file-upload-modal-header {
  position: relative;
}

.milestone-file-upload-modal-header::after {
  content: "";
  position: absolute;
  left: -30px; /* Adjust the value to extend the border line to the left */
  right: -30px; /* Adjust the value to extend the border line to the right */
  bottom: 0px; /* Adjust the value to make the line longer below the border */
  border-bottom: 1px solid lightgrey; /* Set the style, thickness, and color of the border line */
}

.milestone-file-upload-modal-footer {
  position: relative;
}

.milestone-file-upload-modal-footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: -30px;
  right: -30px;
  border-top: 1px solid lightgrey; /* Set the style, thickness, and color of the top border */
}

.milestone-file-upload-exit {
  padding: 5px;
  border: none;
  background-color: #ccc;
  color: #fff;
  font-size: 16px !important;
  text-decoration: none;
  cursor: pointer;
  position: sticky;
  top: 10px;
  right: auto;
  bottom: auto;
  left: 1000px;
  z-index: 100;
  width: 35px;
  height: 35px;
}

@media screen and (max-width: 767px) {
  .milestone-file-upload-box {
    margin-right: -5px;
  }
}

@media (max-width: 1000px) {
  #add-file-upload-button {
    position: absolute !important;
    left: calc(50vw - 70px) !important;
    bottom: 10px;
  }

  #file-upload-info-icon {
    position: absolute !important;
    left: calc(50vw + 70px) !important;
    bottom: 10px;
  }
}

.drop-file-submit-upload {
  margin: 0px !important;
}
