.DraftEditor-root {
  width: 100%;
  padding: 0px 10px;
  overflow-y: auto;
  height: 200px !important;
}

.public-DraftEditor-content {
  max-height: 30vh;
}

.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
  margin: 0px;
}
