.boxes {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-right: -21px;
}

.discount-box {
  max-width: unset !important;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.06);
}

.discount-box h3 {
  margin-left: 25px;
  margin-bottom: -10px !important;
  color: #042470;
  margin-bottom: 10px !important;
  margin-top: 22px !important;
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif;
  font-size: 22px !important;
}

.report-card .report-section {
  width: 100% !important;
  max-width: none;
  display: flex;
  flex-direction: column;
}

.report-card .table-container {
  overflow: scroll;
}

.box {
  box-shadow: unset !important;
}

.report-heading {
  display: flex;
  justify-content: space-between;
}

.discounts-export {
  height: fit-content !important;
  font-size: 20px !important;
}

.report-card {
  width: 70vw !important;
  background-color: white;
  border-radius: 20px;
  margin-right: -20px;
  margin-top: 20px;
  padding-top: 20px;
}

.discounts-graph {
  display: flex;
}

.discount-doughnut {
  height: 250px;
  padding-top: 20px;
}

.customers-filter {
  margin-right: 40px !important;
  background-color: white;
  border-width: 0px;
  margin-left: auto !important;
  margin-top: -50px !important;
}

.customers-filter-menu {
  position: absolute;
  top: 1100px;
  right: 230px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 7px 0 rgba(0,0,0,.6);
  padding: 5px 15px 5px 15px;
}

@media only screen and (max-width: 767px) {
  .report-card {
    padding: 0px;
    padding-left: 5px !important;
    padding-right: 5px !important;
    width: 100% !important;
    margin: 0px !important;
  }

  .report-heading {
    display: flex;
    align-items: center;
  }

  .discounts-export {
    font-size: 12px !important;
    width: fit-content;
  }

  .console-pages .my_earning .cont #discounts-report .boxes .box .info h3 {
    margin-left: -10px;
    margin-bottom: -10px !important;
    color: #042470;
    margin-bottom: 5px !important;
    margin-top: 0px !important;
    font-weight: 500 !important;
    font-family: "Montserrat", sans-serif;
    font-size: 15px !important;
    line-height: 20px !important;
  }

  .discount-box {
    width: 30% !important;
    min-width: unset !important;
    margin-right: 5px !important;
    margin-bottom: 5px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .discount-box .info .info-components {
    display: none;
  }

  #discounts-report .boxes {
    margin-right: 0px !important;
  }

  .discount-box h2 {
    font-size: 11px !important;
    font-weight: 600 !important;
    padding: 5px 8px 4px 8px !important;
    text-align: center;
  }

  .discount-box .info {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .discount-graphs {
    width: 45% !important;
  }

  .discount-doughnut {
    padding-top: 5px !important;
    height: fit-content !important;
  }

  .customers-filter-menu {
    top: 775px;
    right: 70px;
  }
}

@media only screen and (max-width: 960px) {
  .report-card {
    width: 100% !important;
    margin: 0px !important;
  }
}