.order-receipt-container {
  width: 80%;
  display: block;
  margin: auto;
  animation: fadein 0.25s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.order-recipt-header-container {
  position: relative;
  min-height: 240;
  font-family: "Open Sans";
}

.order-logo-wrapper {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 50px;
  width: 50px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 0px 5px 1px rgb(126, 126, 126);
}

.order-logo {
  max-width: 100%;
  max-height: 100%;
}

.order-name-section {
  position: absolute;
  top: 25%;
  left: 15px;
}

.order-name {
  font-size: 18px;
  opacity: 1;
  font-weight: 800;
  color: #4361b3;
  /* margin-bottom: 10px; */
}

.order-date {
  font-size: 18px;
  opacity: 1;
  font-weight: 700;
  color: #495b8c;
}

.order-billing-info-section {
  position: relative;
  bottom: 10px;
  right: 15px;
  text-align: right;
}

.order-bill-title {
  font-size: 16px;
  opacity: 1;
  font-weight: 800;
  color: #495b8c;
  /* margin-bottom: 10px; */
}

.order-bill-name {
  font-size: 16px;
  opacity: 1;
  font-weight: 700;
  color: #495b8c;
  /* margin-bottom: 10px; */
}

.order-bill-address1 {
  font-size: 16px;
  opacity: 1;
  font-weight: 700;
  color: #495b8c;
  /* margin-bottom: 10px; */
}

.order-bill-address2 {
  font-size: 16px;
  opacity: 1;
  font-weight: 700;
  color: #495b8c;
}

.order-receipt-table-container {
}

.order-receipt-table {
  width: 100%;
  font-family: "Open Sans";
}

.order-receipt-header-section {
  border-bottom: 1.5px solid #dbdde3;
  border-top: 1.5px solid #dbdde3;
}

.order-receipt-table-header {
  font-size: 16px;
  font-weight: normal;
  padding: 15px 10px;
  font-weight: 600;
}

.order-receipt-item-section {
}

.order-receipt-item-content {
  font-size: 14px;
  padding: 10px;
  min-width: 100px;
  color: dimgrey;
}
