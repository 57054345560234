.display-container {
  display: flex !important;
}

.left-container {
  background: white !important;
  width: 35% !important;
  border-radius: 10px !important;
  margin-right: 30px !important;
  margin-bottom: 30px !important;
}

.image-container {
  width: 100% !important;
  height: 220px !important;
}

.image {
  width: 100% !important;
  height: 100% !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  object-fit: cover !important;
}

.program-name {
  text-align: center !important;
  color: black !important;
  font-weight: bold !important;
  font-size: 16px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.program-category {
  text-align: center !important;
  color: grey !important;
  font-size: 9px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  font-style: italic !important;
}

.address-container {
  background-color: rgb(242, 242, 240) !important;
  height: 70px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 15px;
}

.address-title {
  font-size: 16px !important;
  font-weight: 800 !important;
  width: 100% !important;
  text-align: center !important;
  color: rgb(52, 63, 100) !important;
  margin-bottom: 0 !important;
}

.options-container {
  width: 100% !important;
}

.option-list {
  padding: 7px 15px;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100% !important;
  color: rgb(165, 165, 165) !important;
  /* cursor: pointer !important; */
}

.option-list:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.option-name {
  font-size: 16px !important;
  margin-bottom: 0 !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400 !important;
}

.option-list:hover {
  background: linear-gradient(
    90deg,
    rgba(3, 171, 240, 1) 0%,
    rgba(74, 70, 243, 1) 50%,
    rgba(49, 112, 188, 1) 100%
  );
  color: #fff !important;
}

.option-img {
  margin-right: 5px !important;
}

.enrollment-btn {
  margin-bottom: 0px;
}

/* Tablets */
@media only screen and (max-width: 960px) {
  .enrollment-btn {
    margin-bottom: 15px;
  }
}

/* Phones */
@media only screen and (max-width: 767px) {
  .display-container {
    display: flex !important;
    flex-wrap: wrap !important;
  }

  .left-container {
    width: 100% !important;
    margin-right: 0px !important;
    margin-bottom: 15px !important;
  }

  .image-container {
    width: 100% !important;
  }

  .image {
  }

  .program-name {
    font-size: 13px !important;
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .program-category {
    text-align: center !important;
    color: grey !important;
    font-size: 10px !important;
    margin-top: 2px !important;
    margin-bottom: 2px !important;
    font-style: italic !important;
  }

  .address-container {
    background-color: rgb(242, 242, 240) !important;
    height: 70px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .address-title {
    font-size: 14px !important;
    font-weight: 700 !important;
    width: 100% !important;
  }

  .options-container {
  }

  .option-list {
    padding: 7px 5px;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    width: 100% !important;
    color: rgb(165, 165, 165) !important;
    /* cursor: pointer !important; */
  }

  .option-name {
    font-size: 16px !important;
    margin-bottom: 0 !important;
    margin-right: 5px !important;
  }

  .option-list:hover {
    background: linear-gradient(
      90deg,
      rgba(3, 171, 240, 1) 0%,
      rgba(74, 70, 243, 1) 50%,
      rgba(49, 112, 188, 1) 100%
    );
    color: #fff !important;
  }

  .option-img {
    margin-right: 5px !important;
  }

  .enrollment-btn {
    margin-bottom: 10px;
  }
}
