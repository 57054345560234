.drop-file-input {
  position: relative;
  width: 400px;
  height: 200px;
  border: 2px dashed var(--border-color);
  border-radius: 20px;
  margin-left: -10px;
  /* margin-right: -20px; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--input-bg);
  transition: height 0.3s ease;
}

@media only screen and (max-width: 767px) {
  .drop-file-input {
    width: 105%;
  }
}

.drop-file-input input {
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.drop-file-input.shrunk {
  height: 60% !important;
}

.drop-file-input:hover,
.drop-file-input.dragover {
  opacity: 0.6;
}

.drop-file-input__label {
  text-align: center;
  color: var(--txt-second-color);
  font-weight: 600;
  padding: 10px;
  font-size: 16px;
}

.drop-file-input__label img {
  width: 100px;
}

.drop-file-preview {
  margin-top: 30px;
  text-align: center;
}

.drop-file-preview p {
  font-weight: 500;
}

.drop-file-preview__title {
  margin-bottom: 20px;
  font-size: 16px !important;
  margin-right: 25px;
  font-family: "Open sans", sans-serif;
}

.drop-file-preview__item {
  position: relative;
  display: flex;
  margin-bottom: 10px;
  background-color: var(--input-bg);
  padding: 15px;
  border-radius: 20px;
  height: 85px;
  font-size: 14px !important;
  margin-right: 25px;
}

.drop-file-preview__item img {
  width: 50px;
  margin-right: 20px;
  height: 50px;
}

.drop-file-preview__item__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .drop-file-preview__item__info {
    max-width: 70%;
  }

  .drop-file-preview__title {
    margin-right: 0px;
  }

  .drop-file-preview__item {
    margin-right: 0px;
  }
}

.drop-file-preview__item__info p {
  max-width: 250px;
  max-height: 50px;
  /* word-wrap: break-word; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.drop-file-preview__item__info input {
  border: 2px solid lightgrey !important;
  border-radius: 10%;
  width: 220px;
}

.drop-file-preview__item__info p[data-tooltip]::before {
  content: attr(data-tooltip);
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: #333;
  color: #fff;
  font-size: 14px !important;
  border-radius: 5px;
  white-space: pre-wrap;
  z-index: 9999;
}

@media (max-width: 767px) {
  .drop-file-preview__item__info p[data-tooltip]:active::before {
    display: block;
  }
}

.drop-file-preview__item__del {
  background-color: var(--box-bg);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: var(--box-shadow);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
  font-size: 16px !important;
}

.drop-file-preview__item:hover .drop-file-preview__item__del {
  opacity: 1;
}

.drop-file-submit-upload {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #4caf50;
  color: #fff;
  font-size: 16px !important;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 25px !important;
}

@media screen and (max-width: 767px) {
  .drop-file-submit-upload {
    margin-right: -4px !important;
  }
}
