.input-wrapper {
  background-color: white;
  width: 90%;
  border-radius: 10px;
  height: 2.5rem;
  padding: 0 15px;
  box-shadow: 0px 0px 8px #ddd;
  display: flex;
  align-items: center;
}

#universal-search-bar {
  background-color: transparent;
  border: none;
  height: 100%;
  font-size: 1rem;
  width: 100%;
  margin-left: 5px;
}

#search-icon {
  color: lightgrey;
}

@media (min-width: 1200px) {
  .input-wrapper {
    background-color: white;
    width: 60%;
    border-radius: 10px;
    height: 2.5rem;
    padding: 0 15px;
    box-shadow: 0px 0px 8px #ddd;
    display: flex;
    align-items: center;
    transition: width 0.3s ease-in-out;
  }

  .input-wrapper.focused {
    width: 90%;
  }
}
