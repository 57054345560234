.preview-card {
  display: flex !important;
  justify-content: space-between !important;
  margin-bottom: 5px !important;
  width: 540px !important;
  border-radius: 7px !important;
  align-items: center !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  cursor: pointer !important;
  background-color: white !important;
  height: auto !important;
}

.card-content-preview {
  display: flex !important;
  width: 75% !important;
  justify-content: space-between !important;
}

#program-listing-preview {
  padding: 20px 0px 5px !important;
  width: 590px;
}

.request-modal-preview {
  max-width: 500px;
  /* width: 100%; */
  min-width: 300px;
  margin: auto;
  padding: 30px;
  z-index: 10001;
  color: black;
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border-radius: 15px;
  outline: 0;
  box-shadow: 0px 0px 15px 3px rgba(211, 211, 211, 0.75);
  -ms-overflow-style: thin;
  scrollbar-width: thin;
  overflow: auto;
}

.preview-listing-footer {
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: -5px;
  background-color: #eeeeee;
}

.preview-listing-btn {
  background-color: rgb(59, 78, 255);
  color: rgb(255, 255, 255);
  cursor: pointer;
  margin: 24px 10px;
  padding: 6px;
  width: 250px;
  border-radius: 25px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  border: none;
}

.sub2-date-preview {
  margin-top: 5px !important;
  color: grey !important;
  font-family: Lato, sans-serif !important;
}

#sub1-name-preview {
  font-size: 12px;
}

.preview-image {
  width: 125%;
  height: 65px;
  width: 110px;
  border-radius: 10px;
}

@media (max-width: 900px) and (max-height: 420px) {
  #sub1-name-preview {
    font-size: 10px !important;
    width: 100%;
  }

  .preview-card {
    width: 100% !important;
  }

  .card-content-preview {
    height: 50px;
  }

  .card-sub1.card-sub1-preview {
    width: 300px !important;
    padding-left: 5px !important;
  }

  .card-sub2.card-sub2-preview {
    width: 80% !important;
  }

  .sub2-timing.sub2-timing-preview {
    font-size: 11px !important;
    width: 70px;
  }
}

@media (max-width: 1024px) and (max-height: 1200px) {
  #sub1-name-preview {
    font-size: 10px !important;
    width: 100%;
  }

  .preview-card {
    width: 100% !important;
  }

  .card-content-preview {
    height: 50px;
  }

  .card-sub1.card-sub1-preview {
    width: 300px !important;
    padding-left: 5px !important;
  }

  .card-sub2.card-sub2-preview {
    width: 80% !important;
  }

  .sub2-timing.sub2-timing-preview {
    font-size: 11px !important;
    width: 70px;
  }
}

@media (max-width: 420px) and (max-height: 900px) {
  #sub1-name-preview {
    font-size: 10px !important;
    width: 100%;
  }

  .preview-card {
    width: 75% !important;
  }

  .card-content-preview {
    height: 50px;
  }

  .card-sub1.card-sub1-preview {
    width: 300px !important;
    padding-left: 5px !important;
  }

  .card-sub2.card-sub2-preview {
    width: 90% !important;
  }

  .sub2-timing.sub2-timing-preview {
    font-size: 12px !important;
    width: 70px;
  }
}

@media (max-width: 767px) and (max-height: 670px) {
  #sub1-name-preview {
    font-size: 10px !important;
    width: 100%;
  }

  .preview-card {
    width: 68% !important;
  }

  .card-content-preview {
    height: 50px;
  }

  .card-sub1.card-sub1-preview {
    width: 300px !important;
    padding-left: 5px !important;
  }

  .card-sub2.card-sub2-preview {
    width: 90% !important;
  }

  .sub2-timing.sub2-timing-preview {
    font-size: 12px !important;
    width: 70px;
  }

  .preview-image {
    width: 125%;
    height: 65px;
    width: 100px;
    border-radius: 10px;
  }
}

@media (max-width: 670px) and (max-height: 380px) {
  #sub1-name-preview {
    font-size: 10px !important;
    width: 100%;
  }

  .preview-card {
    width: 100% !important;
  }

  .card-content-preview {
    height: 50px;
  }

  .card-sub1.card-sub1-preview {
    width: 300px !important;
    padding-left: 5px !important;
  }

  .card-sub2.card-sub2-preview {
    width: 90% !important;
  }

  .sub2-timing.sub2-timing-preview {
    font-size: 12px !important;
    width: 70px;
  }

  .preview-image {
    width: 125%;
    height: 65px;
    width: 100px;
    border-radius: 10px;
  }
}

@media (max-width: 400px) and (max-height: 850px) {
  #sub1-name-preview {
    font-size: 10px !important;
    width: 100%;
  }

  .preview-card {
    width: 75% !important;
  }

  .card-content-preview {
    height: 50px;
  }

  .card-sub1.card-sub1-preview {
    width: 300px !important;
    padding-left: 5px !important;
  }

  .card-sub2.card-sub2-preview {
    width: 80% !important;
  }

  .sub2-timing.sub2-timing-preview {
    font-size: 11px !important;
    width: 70px;
  }
}
