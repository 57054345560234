.membership-cards {}

.membership-cards:hover {
  transform: scale(1.001) translateY(-3px);
  box-shadow: 0 2px 15px 0 rgba(204, 204, 204, 0.5);
}

.invites-title-wrapper {
  display: flex;
  margin-top: 5px;
  margin-bottom: 20px;
}