.boxofcalendar .addButton {
  position: absolute;
  right: 15px;
  width: 35px;
  line-height: 35px;
  height: 35px;
  align-self: center;
  border-radius: 50%;
  border: 0;
}

.boxofcalendar .addButton:focus {
  outline: none;
}

.boxofcalendar .react-datetime-picker {
  width: 100%;
}

.boxofcalendar .react-datetime-picker__wrapper {
  height: 40px;
  border: 0;
  width: 100%;
}

.boxofcalendar .react-datetime-picker__inputGroup__input {
  flex-grow: 1 !important;
}

.boxofcalendar .react-datetime-picker__inputGroup__amPm {
  flex-grow: 0 !important;
  width: 50px !important;
  border: 0 !important;
  padding-left: 5px !important;
}

.boxofcalendar .react-datetime-picker__inputGroup__divider {
  flex-grow: 1;
}
.tui-full-calendar-timegrid-hour {
}

.MuiSvgIcon-root .MuiSelect-icon{
  color: #7EA1FF;
}