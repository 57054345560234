.table-action-button {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 14px !important;
  color: #fff !important;
  padding: 10px 25px !important;
  background: #9bb0eb !important;
  transition: all 0.15s ease !important;
  border: 0 !important;
  margin: 0 1px !important;
}

#true {
  font-size: 0.5em !important;
}

.table-action-button:hover {
  background: #1c3ec3 !important;
  text-decoration: none !important;
}

.table-custom-text {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  color: #000000 !important;
}

.table .action-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: -10px;
}

.table .action-group .icon,
.table .action-group .MuiSvgIcon-root {
  color: #ccc;
  cursor: pointer;
  margin-right: 10px;
}
