.reporting-grid-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    column-gap: 30px;
    row-gap: 20px;
  }
  
  .reporting-card {
    width: 340px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 15px 0 rgba(204, 204, 204, 0.5);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 25px 20px;
    margin: 0px 8px 20px;
    height: 100px;
    cursor: pointer;
  }
  
  .reporting-card:hover {
    transform: scale(1.001) translateY(-3px);
    box-shadow: 0 2px 15px 0 rgba(204, 204, 204, 0.5);
  }
  
  /* Tablet */
  @media only screen and (max-width: 1164px) {
    .reporting-grid-cards {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr;
      column-gap: 20px;
      row-gap: 20px;
    }
  
    .reporting-card {
      width: 350px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 2px 15px 0 rgba(204, 204, 204, 0.5);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 25px 20px;
      margin: 0px 8px 20px;
      height: 100px;
    }
  }
  
  /* Phones */
  @media only screen and (max-width: 767px) {
    .reporting-grid-cards {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(1fr);
      column-gap: 30px;
      row-gap: 20px;
    }
  
    .reporting-card {
      width: 350px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 2px 15px 0 rgba(204, 204, 204, 0.5);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 25px 20px;
      margin: 0px 8px 20px;
      height: 100px;
    }
  }
  