.nav-bar-title {
    width: 600px;
    color: #505050;
    font-size: 22px;
    font-family: 'Montserrat';
    font-weight: 700;
    letter-spacing: .1px;
}


/* Phones */
@media only screen and (max-width: 767px) {
    .nav-bar-title {
        display: none;
    }
}