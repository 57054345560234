.observation-select {
}

.react-dropdown-select-content input::placeholder {
  color: grey;
  font-size: 1.2em;
}

.observation-gallery-photo-width {
  width: 8%;
}

/* Tablet */
@media only screen and (max-width: 1497px) {
  .observation-gallery-photo-width {
    width: 12%;
  }
}

/* Phones */
@media only screen and (max-width: 767px) {
  .observation-gallery-photo-width {
    width: 26%;
  }
}
