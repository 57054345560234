.discount-box .info h3 {
  font-size: 40px !important;
  font-weight: 400 !important;
  color: black !important;
}

.discount-box {
  display: flex !important;
  flex-direction: column;
  padding-top: 0px !important;
}

.blurb {
  font-size: 10px;
  margin-left: 20px;
  margin-top: auto !important;
}