.user-schedule-box {
  overflow: scroll;
  background: white;
  margin: 20px auto;
  width: 60vw;
  min-width: 350px;
}

.user-schedule-header-box {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding: 0 20px;
}

.schedule-nav-header1{
  width: fit-content;
}

.schedule-nav-header2{
  width: fit-content;
}

.schedule-notes{
  display: flex;
  justify-content: center;
  height: 80%;
  margin-top: 25px;
  width: 100%;
  overflow-y: auto;
}

.iMhhpk{
  font-family: 'Open Sans', sans-serif;
}

.lmYoCG {
  min-width: 68px;
}