.filter-container {
  background: #1b55d8;
  width: 100%;
  font-family: futura-pt, sans-serif !important;
}

.filter-section {
  /* display: flex;
  justify-content: flex-start;
  align-items: center; */
  margin-left: 20px;
  padding: 15px 0;
  /* flex-wrap: wrap; */
  display: grid;
  grid-template-columns: 470px repeat(auto-fill, 300px);
  grid-auto-rows: auto;
}

.filter-item {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.filter-label {
  width: 106px;
  text-align: right;
  color: white;
  padding-right: 10px;
  /* font-family: "Montserrat"; */
  font-family: futura-pt, sans-serif !important;
  font-weight: 300;
  font-size: 14px;
}

.date-range-1 {
  display: flex !important;
  width: 170px !important;
  margin-right: 10px !important;
  border-radius: 0 !important;
  border: 1.5px solid #1b55d8 !important;
  align-items: center !important;
}

.date-range-2 {
  display: flex !important;
  width: 170px !important;
  border-radius: 0 !important;
  border: 1.5px solid #1b55d8 !important;
  align-items: center !important;
}

.filter-input {
  width: 170px !important;
  margin-right: 10px !important;
  border-radius: 0 !important;
  border: 1.5px solid #1b55d8 !important;
}

.filter-button-section {
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;
  margin-top: 10px;
}

.filter-button {
  background-color: #0083d7;
  border: 1px solid #0083d7;
  width: 170px;
  border-radius: 7px !important;
  color: white;
  border-bottom: 2px solid #0083d7;
  box-shadow: 2px 3px 5px 0px rgba(191, 200, 245, 1);
}

.filter-button.disabled {
  cursor: not-allowed;
}

.report-top-section {
  margin: 0 30px;
  font-family: futura-pt, sans-serif !important;
}

.report-container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 35px;
  font-family: futura-pt, sans-serif !important;
}

.report-section {
  background-color: white;
  height: min-content;
  width: 90%;
  max-width: 700px;
  border-radius: 10px;
  box-shadow: 0 2px 15px 0 rgba(204, 204, 204, 0.5);
}

.report-section.larger {
  max-width: 900px;
}

.export-button-section {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  margin-top: 15px;
}

.report-heading {
  color: #120fa8;
  /* font-family: "Montserrat"; */
  font-family: futura-pt, sans-serif !important;
  font-weight: 600 !important;
  font-size: 34px !important;
}

.report-subtitles {
  color: #a4b0bb;
  font-size: 14px;
  /* font-family: "Montserrat"; */
  font-family: futura-pt, sans-serif !important;
}

.report-table {
  margin-top: 30px;
  background: #f6f6f6; /*#f7fafc*/
  padding: 30px 0;
  height: 50%;
}

.report-table-large {
  margin: 30px 0;
  background: #f6f6f6; /*#f7fafc*/
  height: min-content;
  max-height: 600px;
  overflow-y: auto;
}

p.report-no-results-text {
  text-align: center;
  margin-top: 10px !important;
}

.table-container {
  margin: 0 30px 25px;
}

.report-new-table {
  width: 100%;
}

.report-table-header-section {
  border-bottom: 1.5px solid #dbdde3;
}

.report-table-header {
  color: #63ace8;
  font-size: 16px;
  font-weight: normal;
  padding: 10px;
}

.table-item-content {
  font-size: 14px;
  padding: 10px;
  min-width: 100px;
}

.table-item-bold {
  font-weight: bold;
}

.table-item-money {
  font-weight: bold;
  text-align: right;
}

/* Used to force a new row in a CSS grid (filter section of reports) */
.grid-new-col {
  grid-column-start: 1;
}

.filter-button-checkbox {
  min-width: 32px;
}

.doughnut-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  font-weight: bold;
  color: #042470;
}

/* Tablets */
@media only screen and (max-width: 960px) {
  .filter-container {
    background: #1b55d8;
    width: 100%;
  }

  .filter-section {
    /* display: flex;
    justify-content: flex-start;
    align-items: center; */
    margin-left: 20px;
    padding: 15px 0;
    /* flex-wrap: wrap; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
  }
}

/* Phones */
@media only screen and (max-width: 767px) {
  .filter-container {
    background: #1b55d8;
    width: 100%;
  }

  .filter-section {
    /* display: flex;
    justify-content: flex-start;
    align-items: center; */
    margin-left: 20px;
    padding: 15px 0;
    /* flex-wrap: wrap; */
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }

  .filter-item {
    display: flex;
    align-items: center;
    padding-right: 10px;
    padding-bottom: 0px; /* changed */
    flex-wrap: wrap; /* changed */
  }

  .date-range-to {
    margin-left: 106px;
  }

  .margin-left-offset {
    margin-left: 100px;
  }

  .report-section {
    background-color: white;
    height: 600px;
    width: 90%; /* changed */
    border-radius: 10px;
    box-shadow: 0 2px 15px 0 rgba(204, 204, 204, 0.5);
  }

  .report-heading {
    color: #120fa8;
    font-family: "Montserrat";
    font-weight: 600 !important;
    font-size: 28px !important; /* changed */
  }

  .doughnut-center {
    font-size: 1.5rem;
  }

}
