.boxes {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-right: -21px;
  }
  
  .name-box {
    max-width: unset !important;
    border-width: 2px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.06);
  }
  
  .name-box h3 {
    margin-left: 25px;
    margin-bottom: -10px !important;
    color: #042470;
    margin-bottom: 10px !important;
    margin-top: 22px !important;
    font-weight: 600 !important;
    font-family: "Montserrat", sans-serif;
    font-size: 30px !important;
    text-align: center;
  }
  
  .report-card .report-section {
    width: 100% !important;
    max-width: none;
    display: flex;
    flex-direction: column;
  }
  
  .report-card .table-container {
    overflow: scroll;
  }
  
  .box {
    box-shadow: unset !important;
  }
  
  .report-heading {
    display: flex;
    justify-content: space-between;
  }
  
  .name-export {
    height: fit-content !important;
    font-size: 20px !important;
  }
  
  .report-card {
    width: 70vw !important;
    background-color: white;
    border-radius: 20px;
    margin-right: -20px;
    margin-top: 20px;
    padding-top: 20px;
  }
  
  .name-graphs {
    display: flex;
  }
  
  .name-doughnut {
    height: 250px;
    padding-top: 20px;
  }
  
  .name-doughnut-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .doughnut-center {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    font-weight: bold;
    color: #042470;
  }
  .filter-item-stacked {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin-left: 10px;
  }
  
  .filter-item-inline {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .filter-label-inline {
    width: 100px;
    text-align: left; /* Align to the left */
    color: white;
    padding-right: 10px;
    font-family: futura-pt, sans-serif !important;
    font-weight: 300;
    font-size: 14px;
  }
  .filter-label-prestacked {
    width: 106px;
    text-align: right;
    color: white;
    font-family: futura-pt, sans-serif !important;
    font-weight: 300;
    font-size: 18px; /* Increased font size */
  }

  .filter-item-prestacked {
    display: flex;
    align-items: center;
    margin-right: 20px; /* Move more towards the left */
  }

  .customers-filter {
    margin-right: 40px !important;
    background-color: white;
    border-width: 0px;
    margin-left: auto !important;
    margin-top: -50px !important;
    width: 100% !important;
  }
  
  .customers-filter select {
    width: 35% !important;
  }
  
  .customers-filter-menu {
    position: absolute;
    top: 1100px;
    right: 230px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 7px 0 rgba(0,0,0,.6);
    padding: 5px 15px 5px 15px;
  }
  
  .name-select {
    min-width: 35%;
    background-color: white;
  }
  
  .report-table-large {
    max-width: 90%; /* Adjusted to make the table smaller length-wise */
    max-height: 400px; /* Adjusted to make the table smaller height-wise */
    overflow-y: auto;
  }
  
  .demographics-info {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .demographics-info h2 {
    margin-bottom: 10px;
  }
  
  .demographics-info p {
    margin: 5px 0;
  }
  
  @media only screen and (max-width: 767px) {
    .report-card {
      padding: 0px;
      padding-left: 5px !important;
      padding-right: 5px !important;
      width: 100% !important;
      margin: 0px !important;
    }
  
    .report-heading {
      display: flex;
      align-items: center;
    }
  
    .name-export {
      font-size: 12px !important;
      width: fit-content;
    }
  
    .console-pages .my_earning .cont #name-report .boxes .box .info h3 {
      margin-left: -10px;
      margin-bottom: -10px !important;
      color: #042470;
      margin-bottom: 5px !important;
      margin-top: 0px !important;
      font-weight: 500 !important;
      font-family: "Montserrat", sans-serif;
      font-size: 20px !important;
      line-height: 20px !important;
    }
  
    .name-box {
      width: 30% !important;
      min-width: unset !important;
      margin-right: 5px !important;
      margin-bottom: 5px !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  
  
    #locations-report .boxes {
      margin-right: 0px !important;
    }
  
    .name-box h2 {
      font-size: 11px !important;
      font-weight: 600 !important;
      padding: 5px 8px 4px 8px !important;
      text-align: center;
    }
  
    .name-box .info {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  
    .name-graphs {
      width: 45% !important;
    }
  
    .name-doughnut {
      padding-top: 5px !important;
      height: fit-content !important;
    }
  
    .doughnut-center {
      font-size: 1rem !important;
    }
  
    .customers-filter-menu {
      top: 775px;
      right: 70px;
    }

    .filter-section {
      margin-left: 0 !important;
      padding: 15px 0 !important;
      display: grid !important;
      grid-template-columns: 1fr !important;
      grid-auto-rows: auto !important;
    }
  
    .filter-item {
      display: flex !important;
      align-items: center !important;
      padding-right: 10px !important;
      padding-bottom: 0px !important;
      flex-wrap: wrap !important;
    }
  
    .filter-container {
      background: #1b55d8 !important;
      width: 100% !important;
      display: flex !important;
      justify-content: flex-start !important;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .name-box {
      width: 100% !important;
      margin-right: 0 !important;
      margin-bottom: 10px !important;
    }
    .boxes {
        flex-direction: column !important;
        align-items: center !important;
    }
    .category-select, .year-select, .name-select {
      min-width: 275px !important;
      max-width: 275 !important;

    }
    .filter-section {
        margin-left: 0 !important;
        padding: 15px 0 !important;
        display: grid !important;
        grid-template-columns: 1fr !important;
        grid-auto-rows: auto !important;
      }
    
      .filter-item {
        display: flex !important;
        align-items: center !important;
        padding-right: 10px !important;
        padding-bottom: 0px !important;
        flex-wrap: wrap !important;
      }
    
      .filter-container {
        background: #1b55d8 !important;
        width: 100% !important;
        display: flex !important;
        justify-content: flex-start !important;
      }
  
    .doughnut-center {
      font-size: 1rem;
    }
  }
  
  @media only screen and (max-width: 960px) {
    .report-card {
      width: 100% !important;
      margin: 0px !important;
    }
  }

  .filter-container {
    background: #1b55d8;
    width: 100%;
    font-family: futura-pt, sans-serif !important;
  }
  
  .filter-section {
    display: flex;
    align-items: center;
    margin-left: 20px;
    padding: 15px 0;
  }
  
  .filter-item {
    display: flex;
    align-items: center;
    padding: 5px 0;
  }
  
  
  .category-select, .year-select, .name-select {
    width: 20vw; /* Adjusted to be a percentage of the screen size */
    background-color: white !important;
  }