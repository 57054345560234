.react-datepicker__month .react-datepicker__month-text {
  width: 3.5rem !important;
}

.custom-date{
  border: 1px solid #e9e6e6 !important;
  padding: 7px 5px !important;
}

@media only screen and (max-width: 400px) {
  .react-datepicker-popper {
    left: -40px !important;
  }
}
