.react-form-builder .react-form-builder-preview.is-editing .edit-form {
  left: 220px;
}
.btn {
  cursor: pointer;
}

.formbuilder-radios {
  display: flex;
  align-items: center;
  overflow-x: scroll;
  gap: 12px;
  font-size: 12px;
}

.formbuilder-radios::-webkit-scrollbar {
  height: 5px;
}

.formbuilder-radio-container {
  display: flex;
  gap: 12px;
  align-items: center;
}

.formbuilder-row {
  display: grid;
  grid-template-columns: 1.2fr 1.2fr 2.3fr 4.5fr 0.7fr 0.7fr;
  align-items: center;
  padding: 15px 25px;
  margin: 0 0 15px 0;
}

.formbuilder-header {
  width: 100%;
  max-width: 650px;
  background-color: #FFF;
  position: relative;
}

.formbuilder-show-require {
  color: #A9A9A9;
  font-size: 0.7em;
}

.formbuilder-section-name {
  color: rgb(42, 64, 144);
  font-weight: bold;
}

.formbuilder-delete-section {
  grid-column: 6;
}

.formbuilder-icon-button {
  justify-self: right;
  cursor: pointer;
}

.formbuilder-icon-button.fa-times-circle {
  color: lightcoral;
}

.formbuilder-icon-button.fa-pencil-alt {
  color: lightslategrey;
}

.formbuilder-item {
  background-color: #FFF;
  border-radius: 20px;
  position: relative;
  cursor: grab;
}

.formbuilder-buttons-row {
  margin: 0 0 15px 0;
  background-color: #FFF;
  width: 100%;
  max-width: 650px;
  max-height: 60px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formbuilder-button {
  background: #c4c8d4;
  border: 1px solid #c4c8d4;
  height: 25px;
  width: 83px;
  border-radius: 5px !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 700;
  font-size: 14px !important;
  line-height: 23px !important;
  color: #fff;
  display: inline-block;
  box-shadow: 3px 4px 5px 0 rgb(198 193 193 / 75%)
}

.formbuilder-input, .formbuilder-textarea {
  padding: 10px;
}