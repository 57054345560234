#modal-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  z-index: 9988;
  display: flex;
  align-items: center;
}

#modal-bg .boxforc {
  background: #fff;
  z-index: 9999;
  width: 280px;
  margin: auto;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
}

#modal-bg .boxforc h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: #000000;
  padding: 10px 15px 5px;
  margin: 0;
}

#modal-bg .boxforc .modal-row {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
  padding: 10px 15px 10px;
  margin: 0;
  border-bottom: 1px solid #c4c4c4;
}

#modal-bg .boxforc .modal-row img {
  position: relative;
  margin-right: 6px;
  margin-top: -2px;
}

#modal-bg .boxforc .arrowtoppopup {
  margin: auto;
  margin-top: -26px;
  position: relative;
  left: 50px;
  display: block;
}

#modal-bg .boxforc button {
  background: transparent;
  color: #7ea1ff;
  font-style: 11px;
  line-height: 15px;
  display: block;
  border: 1px solid #7ea1ff;
  max-width: 57px;
  float: right;
  text-align: center;
  padding: 5px 0 4px !important;
  width: 100%;
  border-radius: 3px;
  margin-top: 7px;
  margin-bottom: 5px;
  margin-right: 5px;
}

#modal-bg .boxforc button:hover {
  color: #fff;
  background-color: #7ea1ff;
}
