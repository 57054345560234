.close-btn {
  position: absolute;
  top: 7px;
  right: 12px;
  cursor: pointer;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-btn:hover {
  background-color: rgb(236, 235, 235);
}

.dnd-grab-cursor:hover {
  cursor: grab;
}

.price-card-container {
  display: flex;
  overflow-x: scroll;
  padding-top: 10px;
  margin-top: -19px;
  margin-bottom: -10px;
}

.price-card-container > .add-plan {
  height: 60vh !important;
  min-height: 438px !important;
  width: 5vw !important;
  min-width: 76px !important;
}

#no-plans-button {
  width: 16vw !important;
  min-width: 245px !important;
}

#onlinePriceCardContainer {
  max-width: 53vw;
}