.tos-modal-cont {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  display: flex;
  font-family: "Quicksand", sans-serif;
  font-size: 13px;
}

.tos-modal {
  max-width: 800px;
  min-width: 300px;
  background-color: white;
  border: 2px solid #8d60ff;
  position: relative;
  margin: auto;
  border-radius: 8px;
  padding: 40px 2%;
  z-index: 10001;
  text-align: center;
  color: black;
  line-height: 28px;
}

.tos-modal label {
  margin: 14px 0 0;
}

.tos-modal button {
  margin: 14px auto 0 !important;
  background-color: #4e73df;
  border: 1px solid #2750cc;
  border-radius: 4px !important;
  color: white;
  padding: 8px !important;
}

.tos-modal .backbtn {
  background-color: #df4e4e;
  border: 1px solid #cc2727;
}
