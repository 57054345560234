.select-tab {
  width: 100%;
  background-color: white;
  height: fit-content;
  display: flex;
  padding: 10px 0;
}

.user-wrapper {
  margin: 0 auto;
  width: 93%;
}

.user-block {
  display: flex;
  height: 80px;
}

.user-details {
  display: flex;
  width: 30%;
  height: 80px;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .user-details {
    width: 100%;
    justify-content: center;
  }

  .user-block {
    display: inline;
  }

  .tab-items {
    justify-content: space-between;
  }
}

.user-img-container {
  /* margin-right: 8px; */
}

.user-info {
  margin-left: 15px;
}

.user-name {
  color: #61616f;
  font-weight: 700;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
}

.user-particular {
  font-size: 13px;
  color: lightslategrey;
  font-family: "Open Sans", sans-serif;
  font-style: italic;
}

.user-upper-tabs {
  width: 85%;
  margin: 16px auto;
}

.tab-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-title {
  width: 25%;
  height: 100%;
  text-align: center;
  color: #61616f;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  margin-top: 20px;
}

.active-user-tab {
  border-bottom: 3px solid #61616f;
}

.user-box {
  background: white;
  height: 500px;
  margin: 20px auto 60px;
  width: 60vw;
  min-width: 350px;
}

.user-nav-line {
  display: flex;
  background: white;
  width: 100%;
  justify-content: space-evenly;
  height: 50px;
  align-items: center;
  border-bottom: 0.5px solid #e1e0e0;
}

.left-box {
  margin-left: 20px;
}

.pencil-style {
  color: #757575;
  cursor: pointer;
}

.right-box {
  width: 75%;
  display: flex;
  justify-content: space-around;
}

.user-sub-header {
  color: #61616f;
  width: fit-content;
  text-align: center;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
}

.active-user-sub-header {
  border-bottom: 2px solid #ff6663;
}

.individual-info {
  margin: 20px;
}


.user-row {
  display: flex;
  height: 40px;
  align-items: center;
}

.user-label {
  color: #61616f;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  margin-right: 2rem;
  width: 50%;
}

.user-item-content {
  width: 100%;
  color: #61616f;
  font-weight: 300;
  font-family: "Open Sans", sans-serif;
}

.emergency-info {
  margin: 20px;
}

.other-info {
  margin: 20px;
}

.user-certificate-title-section{
    display: flex;
    align-items: center;
}

.user-certificate-title{
    color: #61616f;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    font-size: 17px;

}

.user-certificate-add-btn{
  background: #7B66FF;
  color: white;
  border: 0;
  width: 60px;
  border-radius: 7px !important;
  margin-left: 27px !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-certificate-table-headers{
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin-top: 15px;
}

.user-header-boundry1{
  width: 25%;
  margin-right: 10px;
}

.user-header-boundry2{
  width: 35%;
  margin-right: 10px;
}

.user-header-boundry3{
  width: 40%;
  margin-right: 10px;
}

.user-header{
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600;
  text-decoration: underline;
}

.user-certificate-table-data{
  display: flex;
  margin: 10px 0;
}

.user-data-boundry1{
  width: 25%;
  margin-right: 10px;
}

.user-data-boundry2{
  width: 35%;
  margin-right: 10px;
}

.user-data-boundry3{
  width: 40%;
  margin-right: 10px;
}

.user-data{
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
}

.user-input-field {
  width: 100%;
  border: none;
  border-bottom: 1px solid lightgrey;
}

.user-input-field:focus {
  outline: none;
  border-bottom: 1px solid lightgrey;
}

.user-save-row {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.user-save-btn {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 600;
  font-size: 14px !important;
  line-height: 23px !important;
  color: #ffffff;
  display: inline-block;
  background: #1EC0B1;
  border: 1px solid #7ea1ff;
  padding: 5px 11px;
  border-radius: 5px !important;
}
