/* .react-calendar__tile .react-calendar__tile--active .react-calendar__tile--range .react-calendar__tile--rangeStart .react-calendar__tile--rangeEnd .react-calendar__tile--rangeBothEnds .react-calendar__month-view__days__day{
    border-radius: 50%;
} */
/* 
.react-calendar__tile {
    height: 30px;
    width: 25px;
    border-radius: 50%;
} */

/* .react-calendar__tile--active {
    border-radius: 50% !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #e70220;
} */

.react-calendar__tile {
  flex-basis: 8.3857% !important;
  margin-left: 20px !important;
  margin-bottom: 5px !important;
}

.react-calendar__month-view__weekdays__weekday {
  flex-basis: 8.2857% !important;
  margin-left: 20px !important;
  color: #9dbfff !important;
  font-size: 11px !important;
}

.react-calendar__navigation__label__labelText {
  color: #64646b !important;
  font-size: 1rem !important;
  font-weight: normal !important;
}

.react-calendar__navigation {
  margin-top: 10px !important;
  height: 25px !important;
  text-align: center !important;
}

.react-calendar {
  height: 260px !important;
  margin-top: 20px !important;
}

.react-calendar__navigation__label__labelText--from {
  color: #64646b !important;
  font-size: 1rem !important;
  font-weight: normal !important;
}

.react-calendar__year-view__months__month {
  flex-basis: 15.2857% !important;
}

.react-calendar__month-view__days__day--weekend {
  color: #000 !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #d2d2d2 !important;
}

.react-calendar__navigation button[disabled] {
  background-color: white !important;
}

/* .react-calendar__month-view__days{
    justify-content: space-between !important;
} */

/* .react-calendar__tile--range{
    background-color: lightpink !important;
} 
.react-calendar__tile--rangeStart{
    background-color: lightpink !important;
}
.react-calendar__tile--rangeEnd {
    background-color: lightpink !important;
}
.react-calendar__tile--rangeBothEnds{
    background-color: lightpink !important;
} */
/* 
abbr {
    text-decoration: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFAFAE !important;
    color: #A26271 !important;
  } */
