.days .day {
  cursor: pointer;
  user-select: none;

  padding: 12px 0;
  font-size: 14px;
  text-align: center;
  color: #9b9b9b;
  border-right: solid 1px #d0d2d5;
  border-top: solid 1px #d0d2d5;
  border-bottom: solid 1px #d0d2d5;
  background-color: #fff;
  margin: 0;
  float: left !important;
  width: 14% !important;
  display: inline-block !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.days .daytoni {
  cursor: pointer;
  user-select: none;

  padding: 12px 0;
  font-size: 14px;
  text-align: center;
  color: #9b9b9b;
  border-right: solid 1px #d0d2d5;
  border-top: solid 1px #d0d2d5;
  border-bottom: solid 1px #d0d2d5;
  background-color: #fff;
  margin: 0;
  float: left !important;
  width: 20% !important;
  display: inline-block !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.days .daytoni.day-monday {
  border-left: solid 1px #d0d2d5;
  border-radius: 4px 0 0 4px;
}
.days .day.day-monday {
  border-left: solid 1px #d0d2d5;
  border-radius: 4px 0 0 4px;
}
.days {
  margin: 0 auto;
  text-align: center;
}
.days .daytoni.day-sunday {
  border-radius: 0 4px 4px 0;
}
.days .day.day-sunday {
  border-radius: 0 4px 4px 0;
  display: block;
  width: 16% !important;
}
.selecta {
  color: white !important;
  background-color: #ff6663 !important;
  border-color: none !important;
}

.inputStyle {
  all: none !important;
  border: 1px solid grey !important;
  border-radius: 10px;
}
.inputStyle input {
  border-bottom: none !important;
}
.submenu:hover {
  text-decoration: none !important;
}
.tableticket {
  margin-top: 20px;
}
