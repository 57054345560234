.step-name {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 700;
    user-select: none;
}

.step-color {
    color: #7c8295;
}

.console-pages .statusIndicator {
    padding-top: 9px;
    padding-bottom: 20px;
    padding-left: 3rem;
    background: #ffffff;
  }

  @media (max-width: 1400px) {
    .console-pages .statusIndicator {
        padding-left: 20px;
      }
  }