.boxes {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-right: -21px;
}

.location-box {
  max-width: unset !important;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.06);
}

.location-box h3 {
  margin-left: 25px;
  margin-bottom: -10px !important;
  color: #042470;
  margin-bottom: 10px !important;
  margin-top: 22px !important;
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif;
  font-size: 22px !important;
  text-align: center; /* Center align the h3 */
}

.report-card .report-section {
  width: 100% !important;
  max-width: none;
  display: flex;
  flex-direction: column;
}

.report-card .table-container {
  overflow: scroll;
}

.box {
  box-shadow: unset !important;
}

.report-heading {
  display: flex;
  justify-content: space-between;
}

.locations-export {
  height: fit-content !important;
  font-size: 20px !important;
}

.report-card {
  width: 70vw !important;
  background-color: white;
  border-radius: 20px;
  margin-right: -20px;
  margin-top: 20px;
  padding-top: 20px;
}

.locations-graph {
  display: flex;
}

.location-doughnut {
  height: 250px;
  padding-top: 20px;
}

.location-doughnut-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.doughnut-center {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  font-weight: bold;
  color: #042470;
}

.customers-filter {
  margin-right: 40px !important;
  background-color: white;
  border-width: 0px;
  margin-left: auto !important;
  margin-top: -50px !important;
}

.customers-filter-menu {
  position: absolute;
  top: 1100px;
  right: 230px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 7px 0 rgba(0,0,0,.6);
  padding: 5px 15px 5px 15px;
}

@media only screen and (max-width: 767px) {
  .report-card {
    padding: 0px;
    padding-left: 5px !important;
    padding-right: 5px !important;
    width: 100% !important;
    margin: 0px !important;
  }

  .report-heading {
    display: flex;
    align-items: center;
  }

  .locations-export {
    font-size: 12px !important;
    width: fit-content;
  }

  .console-pages .my_earning .cont #locations-report .boxes .box .info h3 {
    margin-left: -10px;
    margin-bottom: -10px !important;
    color: #042470;
    margin-bottom: 5px !important;
    margin-top: 0px !important;
    font-weight: 500 !important;
    font-family: "Montserrat", sans-serif;
    font-size: 15px !important;
    line-height: 20px !important;
  }

  .location-box {
    width: 30% !important;
    min-width: unset !important;
    margin-right: 5px !important;
    margin-bottom: 5px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }


  #locations-report .boxes {
    margin-right: 0px !important;
  }

  .location-box h2 {
    font-size: 11px !important;
    font-weight: 600 !important;
    padding: 5px 8px 4px 8px !important;
    text-align: center;
  }

  .location-box .info {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .location-graphs {
    width: 45% !important;
  }

  .location-doughnut {
    padding-top: 5px !important;
    height: fit-content !important;
  }

  .doughnut-center {
    font-size: 1rem !important;
  }

  .customers-filter-menu {
    top: 775px;
    right: 70px;
  }
}

@media only screen and (max-width: 480px) {
  .boxes {
    flex-direction: column !important;
    align-items: center !important;
  }

  .location-box {
    width: 100% !important;
    margin-right: 0 !important;
    margin-bottom: 10px !important;
  }

  .location-doughnut {
    height: 200px !important;
  }

  .doughnut-center {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 960px) {
  .report-card {
    width: 100% !important;
    margin: 0px !important;
  }
}