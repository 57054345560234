.listing-card-wrapper {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #ececec;
  min-height: 190px;
  margin-bottom: 30px;
  transition: box-shadow 0.2s;
}

.listing-card-wrapper:hover {
  box-shadow: 1px 3px 5px 3px #d4d4d4;
}

.listing-content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px;
  flex-grow: 1;
}

.column-left {
  display: flex;
  width: 500px;
}

.column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Open Sans";
  width: max-content;
  padding-top: 35px;
  margin-right: auto;
}

.column-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 0px;
  overflow: visible;
}

.listing-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 35px;
}

@media only screen and (max-width: 767px) {
  .listing-name {
    font-size: 15px !important;
  }
  .listing-days-of-week, .location-header {
    font-size: 12px !important;
  }
  .listing-description, .listing-age-text, .location-text, .listing-plan-types {
    font-size: 10px !important;
  }

  .column-center {
    display: none;
  }

  .listing-plans {
    flex-direction: column !important;
    justify-content: flex-end;
  }

  .listing-price-text {
    font-size: 14px !important;
  }

  .listing-info {
    padding-left: 5px !important;
  }

  .listing-card-wrapper {
    min-height: 130px;
    height: max-content;
  }

  .listing-content-wrapper {
    padding: 5px !important;
  }

  .column-left {
    white-space: wrap !important;
    width: 65%;
  }

  .listing-plan-types {
    width: 65px !important;
    min-width: fit-content;
    margin-bottom: 5px;
  }

  .listing-card-wrapper .card-img {
    min-width: 90px;
    min-height: 90px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .listing-card-wrapper img {
    height: 100px !important;
    width: auto !important;
  }
}